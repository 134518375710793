import { Component, OnInit, Input } from '@angular/core';
import { LoadingController, NavParams, ModalController, AlertController, ToastController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-cancel-freeze',
  templateUrl: './cancel-freeze.page.html',
  styleUrls: ['./cancel-freeze.page.scss'],
})
export class CancelFreezePage implements OnInit {
  public loading;
  cancelAlert: any;
  public ResumeDetails;
  public toast;
  resumealert: any;

  @Input() cancelFreezeArr;
  constructor(
    private router: Router,
    public alertCtrl: AlertController,
    public modalController: ModalController,
    public navParams: NavParams,
    public translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private storage: Storage,
    private HTTPS: HttpService,
    public toastController: ToastController
  ) {


    this.ResumeDetails=this.navParams.get('cancelFreezeArr')
    console.log(this.navParams.get('cancelFreezeArr'));
    this.translateService.get('RESUMESUCCESS').subscribe(
      value => {
        this.cancelAlert = value;
      });

   }

  ngOnInit() {
  }

  //loader 
  async presentLoading() {
    let waitText
    this.translateService.get('PLEASEWAIT').subscribe(value=>{
      waitText = value;
    })
    this.loading = await this.loadingCtrl.create({
      duration: 1000,
      message: waitText,
    });
    return await this.loading.present();
  }

  

  async presentToastWithOptions(ToastMessage) {
 this.toast = await this.toastController.create({
      message: ToastMessage,
      position: 'top',
      duration: 3000
    });
    this.toast.present();
  }


  backtoMyMembership() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }



  cancelNow() {
    this.presentLoading();
    this.storage.get('tocken').then((val) => {
      let tocken = val;
      this.storage.get("langSelect").then((val) => {
        let ISOCODE;
        let cacheval;
        if (val) {
          if (val == "en-US" || val == "En") {
            ISOCODE = "en-US";
          }
          else if (val == "ar-Sa" || val == "Ar") {
            ISOCODE = "ar-Sa";
          }
        }
        this.HTTPS.CancelProductFreezePost(this.ResumeDetails, tocken, ISOCODE)
        .subscribe((response) => {
          this.loading.dismiss();
          if (response.data) {
            if (response.data.error) {
              this.presentToastWithOptions(response.data.error)
              this.backtoMyMembership()
            }
            else if (response.data.status) {
              this.translateService.get('RESUMESUCCESS').subscribe(
                value => {
                  this.cancelAlert = value;
                });
                this.presentToastWithOptions(this.cancelAlert);
                this.backtoMyMembership();
            }
          }
        });
      })
    })
  }

}
