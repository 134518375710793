import { Injectable, OnInit } from "@angular/core";
import { Storage } from '@ionic/storage';
import { CartServiceService } from 'src/app/services/cart-service/cart-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Events, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class StateStore {
    map = new Map();
    state: string[] = [];
    public products: any[] = [];
    waiverEnable:boolean
    currState;
    public memberMandatoryFields = new BehaviorSubject<string[]>([]);
    public offerDetails = new BehaviorSubject<any>([]);
    public isDocUpload = new BehaviorSubject<boolean>(false)

    constructor(private storage: Storage,
        private _cartService: CartServiceService,
        private translateService: TranslateService,
        private event: Events,
        private toastController: ToastController,
        private route: ActivatedRoute) {
        this.map.set('product', this.goToProduct);
        this.map.set('profile', this.goToProfile);
        this.map.set('waiver', this.goToWaiver);
        this.map.set('cart', this.goToCart);
        this.map.set('onedaypass', this.goToOneDayPass);
        this.map.set('giftaMembership', this.goToGiftaMembership);
        this.map.set('giftvoucher', this.goToGiftVoucher);
        // event.unsubscribe('waiverEnabled')
        // event.subscribe('waiverEnabled',val=>this.waiverEnable=val)
        this.storage.get('enabledFeatures').then(val=>{
            let feature=val
            this.waiverEnable = feature.includes('Waiver')?true:false
          })
    }
    setMandatoryFields(fields: string[]) {
        this.memberMandatoryFields.next(fields);
    }

    setOfferDetails(offerDetails) {
        this.offerDetails.next(offerDetails);
    }

    setIsDocUpload(isDocUpload) {
        this.isDocUpload.next(isDocUpload)
    }

    CurrentState() {
        return this.state[this.state.length - 1];
    }

    initializePage(state) {
        this.state.push(state)
        this._cartService.CartState.subscribe((state: CartState) => {
            console.log('cartservice products', state.products)
            this.products = state.products;
        });
    }

    onStatechange() {
        this.event.publish('emptyCouponGV')
        console.log(this.state, "on statechange state")
        console.log(this.CurrentState(), 'currState')
        this.map.get(this.CurrentState())();
    }


    goToProduct = () => {
        if (this.products && this.products.length > 0) {
            console.log('products available')
            this.state.push('profile')
            this.onStatechange()
        } else {
            let addProductText
            this.translateService.get('ADDPRODUCTS').subscribe(value => {
                addProductText = value;
            });
            this.toastLoading(addProductText)
        }
    }

    goToProfile = () => {
        let status: boolean
        let offerproducts: any = this.products.filter(p => p.type == 'offer');
        offerproducts.forEach(element => {
            this.setOfferDetails(element.offerDetails)
        });
        console.log(this.memberMandatoryFields.getValue())

        if (this.memberMandatoryFields.getValue().length > 0 || (this.offerDetails && this.offerDetails.getValue().OfferDocumentsRequired && this.offerDetails.getValue().OfferDocumentsRequired.length > 0 && this.offerDetails.getValue().OfferDocumentsRequired.filter(a => a['Available'] == 'False').length > 0)) {
            if (this.offerDetails && this.offerDetails.getValue().OfferDocumentsRequired && this.offerDetails.getValue().OfferDocumentsRequired.length > 0 && this.offerDetails.getValue().OfferDocumentsRequired.filter(a => a['Available'] == 'False').length > 0) {
                this.setIsDocUpload(true)
            } else {
                this.setIsDocUpload(false)
            }
            this.event.subscribe('status', val => {
                status = val;
            })
            if (status == true) {
                this.checkWaiver()
            }
        } else {
            this.checkWaiver()
        }
    }

    checkWaiver() {
        if(this.waiverEnable){
            this.storage.get('WaiverStatus').then(val => {
                if (val == true) {
                    this.state.push('cart');
                    this.event.publish('calcTaxes',true);
                } else {
                    this.state.push('waiver')
                    this.event.publish('getWaiverDetails')
                }
            })
        }else{
            this.state.push('cart')
            this.event.publish('calcTaxes',true);
        }
    }

    goToWaiver = () => {
        this.storage.get('WaiverStatus').then(val => {
            console.log('waiverstatus', val)
            if (val == true) {
                this.state.push('cart')
                this.event.publish('calcTaxes', true)
                this.onStatechange()
                return false
            }
            else {
                this.event.publish('verifyWaiverOTP')
            }
        })
    }

    goToCart = () => {
        console.log('cart page')
    }

    goToOneDayPass = () => {
        this.state.push('profile')
        this.onStatechange()
    }

    goToGiftaMembership() {
        console.log("giftaMembership state")
        this.state.push('profile')
    }

    goToGiftVoucher() {
        this.state.push('profile')
    }

    async toastLoading(message: string) {
        let toast = await this.toastController.create({
            message: message,
            duration: 3000,
            position: 'bottom'
        });
        return toast.present();
    }
}
interface CartState {
    added: boolean;
    products: any[];
}
