import { NgModule } from '@angular/core';
import { ReplacePipe } from './replace/replace';
import { OrderByPipe } from './order-by/order-by';
@NgModule({
	declarations: [ReplacePipe,
    OrderByPipe],
	imports: [],
	exports: [ReplacePipe,
    OrderByPipe]
})
export class PipesModule {
	static forRoot() {
		return {
			ngModule: PipesModule,
			providers: [],
		};
	 }



}
  
