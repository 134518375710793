import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ApigetService {

  constructor(private http: HttpClient) { }

  apiGet() {
      return this.http.get('assets/CountryCodeFinal.json');
  }
  getNationality() {
    return this.http.get('assets/Nationality.json')
  }
  getLocWithoutIP(): Observable<any> {
    return this.http.get<any>('https://pro.ip-api.com/json/?key=MAkQGoxG3ZmGnth');
  }
}
