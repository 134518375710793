import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.component.html',
  styleUrls: ['./terms-policy.component.scss'],
})
export class TermsPolicyComponent implements OnInit {
  
  header: String;
  themeName;

  constructor(
    private translateService: TranslateService, private navParams: NavParams,
    private modalController: ModalController
  ) {
    this.themeName = this.navParams.get('themeName')
  }
 
  ngOnInit() {
    let type = this.navParams.get('type');
    if (type == 'policy') { 
      this.translateService.get('PRIVACYPOLICY').subscribe(val => {
        this.header = val;
      })
    }
    else {
      this.translateService.get('TERMS&CONDITIONS').subscribe(val => {
        this.header = val;
      })
    }
  }

  getDetails() {
      return this.navParams.get('details');
  }

  close() {
    this.modalController.dismiss();
  }
}
