import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class CartCouponsService {

  constructor(public http: Http) { }
  public getAllCouponsDetails(header): Promise<any> {
    let results;
    let coupons = this.http.get('assets/CartCoupon.json')
      .toPromise()
      .then(response => {
        results = response;
        console.log(results, 'promise')
  
        return results;
      })
      .catch();
    return Promise.resolve(coupons);
  }

}
