import { Storage } from '@ionic/storage';
import { HttpService } from 'src/app/services/http/http.service';
import { async } from '@angular/core/testing';
import { Component, OnInit, Input } from '@angular/core';
import { modalController } from '@ionic/core';
import { MenuController, ModalController, LoadingController } from '@ionic/angular';
import { $ } from 'protractor';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.page.html',
  styleUrls: ['./withdraw.page.scss'],
})
export class WithdrawPage implements OnInit {
  @Input() classDetails: any;
  data: any;
  themeName;

  constructor(private menuCtrl: MenuController,
    public toastController: ToastController,
    private modalCtrl: ModalController,
    public theme: ThemeService,
    public translateService: TranslateService,
    private loadingController: LoadingController,
   private https: HttpService, private storage: Storage) { }

  ngOnInit() {
    this.data = this.classDetails;

    this.theme.storedThemetype.then(theme => {
      this.themeName = theme
    })
  }

  closeModal() {

    this.modalCtrl.dismiss();
    // modalController.dismiss();
  }

  async withdraw() {
    this.presentLoading();
    let token: any;
    let response: any;
    await this.storage.get('tocken').then((val) => {
      token = val;
      if (token) {
        this.https.GoToWithDrawClass(this.data, token).subscribe((res) => {
          response = res;
          if (response.status == 200) {
            this.loadingController.dismiss();
            this.translateService.get('YOURCLASSBOOKINGHASBEENCANCELLED').subscribe(
              value => {
                let ToastMessege = value;
                this.loadingController.dismiss();
                this.presentToast(ToastMessege)
              }
            );
            this.modalCtrl.dismiss();
            // modalController.dismiss();
          }
          else{
            this.loadingController.dismiss();
          }
        });
      }
    });
  }

  async presentToast(Messege) {
    const toast = await this.toastController.create({
      message: Messege,
      duration: 2000,
      position: 'bottom'
    });
    toast.present();
  }

  async presentLoading() {
    let waitText;
    this.translateService.get('PLEASEWAIT').subscribe(value=>{
      waitText = value;
    })
    const loading = await this.loadingController.create({
      message: waitText,
      showBackdrop: true,
      duration: 10000
    });
    return await loading.present();
  }
}
