import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataResolverService } from '../app/services/data-resolver/data-resolver.service';
import { AuthGuardService } from './services/authGuard/auth-guard.service';



const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login', loadChildren: './pages/login/login.module#LoginPageModule'
    , canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule', canActivate: [AuthGuardService]
  },
  { path: 'myprofile', canActivate: [AuthGuardService], loadChildren: './pages/myprofile/myprofile.module#MyprofilePageModule' },
  { path: 'classes', canActivate: [AuthGuardService], loadChildren: './pages/classes/classes.module#ClassesPageModule' },
  { path: 'class-details/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/class-details/class-details.module#ClassDetailsPageModule' },
  { path: 'feedback-home', canActivate: [AuthGuardService], loadChildren: './pages/feedback-home/feedback-home.module#FeedbackHomePageModule' },
  { path: 'feedback-email/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/feedback-email/feedback-email.module#FeedbackEmailPageModule' },
  { path: 'otp/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/otp/otp.module#OtpPageModule' },
  { path: 'logoutconfirm', canActivate: [AuthGuardService], loadChildren: './pages/logoutconfirm/logoutconfirm.module#LogoutconfirmPageModule' },
  { path: 'trainer-list', canActivate: [AuthGuardService], loadChildren: './pages/trainer-list/trainer-list.module#TrainerListPageModule' },
  {
    path: 'trainer-details/:id', canActivate: [AuthGuardService],
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/trainer-details/trainer-details.module#TrainerDetailsPageModule'
  },
  {
    path: 'club-details/:id', canActivate: [AuthGuardService],
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/club-details/club-details.module#ClubDetailsPageModule'
  },
  { path: 'image-full-view', canActivate: [AuthGuardService], loadChildren: './pages/image-full-view/image-full-view.module#ImageFullViewPageModule' },
  { path: 'photo-viewer', canActivate: [AuthGuardService], loadChildren: './pages/photo-viewer/photo-viewer.module#PhotoViewerPageModule' },
  { path: 'about', canActivate: [AuthGuardService], loadChildren: './pages/about/about.module#AboutPageModule' },

  {
    path: 'club-locator/:id', canActivate: [AuthGuardService],
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/club-locator/club-locator.module#ClubLocatorPageModule'
  },
  { path: 'select-language', canActivate: [AuthGuardService], loadChildren: './pages/select-language/select-language.module#SelectLanguagePageModule' },
  { path: 'my-schedule', canActivate: [AuthGuardService], loadChildren: './pages/my-schedule/my-schedule.module#MySchedulePageModule' },
  { path: 'my-membership', canActivate: [AuthGuardService], loadChildren: './pages/my-membership/my-membership.module#MyMembershipPageModule' },
  {
    path: 'buy-membership/:id', canActivate: [AuthGuardService],
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/buy-membership/buy-membership.module#BuyMembershipPageModule'
  },
  {
    path: 'become-member/:id', canActivate: [AuthGuardService],
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/become-member/become-member.module#BecomeMemberPageModule'
  },
  {
    path: 'purchase-list', canActivate: [AuthGuardService],

    loadChildren: './pages/purchase-list/purchase-list.module#PurchaseListPageModule'
  },
  { path: 'call-back-url', canActivate: [AuthGuardService], loadChildren: './pages/call-back-url/call-back-url.module#CallBackUrlPageModule' },
  {
    path: 'purchase-details/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/purchase-details/purchase-details.module#PurchaseDetailsPageModule'
  },
  {
    path: 'gift-voucher/:id',
    resolve: {
      special: DataResolverService
    },
    loadChildren: './pages/gift-voucher/gift-voucher.module#GiftVoucherPageModule'
  },
  { path: 'offer-list', canActivate: [AuthGuardService], loadChildren: './offer-list/offer-list.module#OfferListPageModule' },

  { path: 'appointment-modal', canActivate: [AuthGuardService], loadChildren: './appointment-modal/appointment-modal.module#AppointmentModalPageModule' },
  { path: 'appointment-booked/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/appointment-booked/appointment-booked.module#AppointmentBookedPageModule' },
  { path: 'appointment-cancel-modal', canActivate: [AuthGuardService], loadChildren: './appointment-cancel-modal/appointment-cancel-modal.module#AppointmentCancelModalPageModule' },
  { path: 'change-language', canActivate: [AuthGuardService], loadChildren: './pages/change-language/change-language.module#ChangeLanguagePageModule' },
  { path: 'network-error', canActivate: [AuthGuardService], loadChildren: './pages/network-error/network-error.module#NetworkErrorPageModule' },
  { path: 'waiver-terms', canActivate: [AuthGuardService], loadChildren: './pages/waiver-terms/waiver-terms.module#WaiverTermsPageModule' },
  {
    path: 'billing-load/:id', canActivate: [AuthGuardService], loadChildren: './pages/billing-load/billing-load.module#BillingLoadPageModule',
    resolve: {
      special: DataResolverService
    }
  },
  { path: 'date-time/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/date-time/date-time.module#DateTimePageModule' },
  { path: 'specific-trainer/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/specific-trainer/specific-trainer.module#SpecificTrainerPageModule' },
  { path: 'in-app-browser/:id', canActivate: [AuthGuardService], resolve: { special: DataResolverService }, loadChildren: './pages/in-app-browser/in-app-browser.module#InAppBrowserPageModule' },
  { path: 'settings', canActivate: [AuthGuardService], loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'payment-call-back', canActivate: [AuthGuardService], loadChildren: './payment-call-back/payment-call-back.module#PaymentCallBackPageModule' },
  { path: 'maintenance', canActivate: [AuthGuardService], loadChildren: './pages/maintenance/maintenance.module#MaintenancePageModule' },
  { path: 'class-attendance-popup', canActivate: [AuthGuardService], loadChildren: './pages/class-attendance-popup/class-attendance-popup.module#ClassAttendancePopupPageModule' },
  { path: 'class-rating', canActivate: [AuthGuardService], loadChildren: './pages/class-rating/class-rating.module#ClassRatingPageModule' },
  { path: 'appointments-select-purpose', loadChildren: './pages/appointments-select-purpose/appointments-select-purpose.module#AppointmentsSelectPurposePageModule' },
  { path: 'gv-summary/:id', loadChildren: './pages/gv-summary/gv-summary.module#GvSummaryPageModule', resolve: { special: DataResolverService } },
  { path: 'map-reminder', loadChildren: './pages/map-reminder/map-reminder.module#MapReminderPageModule' },
  { path: 'gif-splash', loadChildren: './gif-splash/gif-splash.module#GifSplashPageModule' },
  {
    path: 'one-day-pass/:id', loadChildren: './pages/one-day-pass/one-day-pass.module#OneDayPassPageModule', resolve: {
      special: DataResolverService
    }
  },
  {
    path: 'one-day-pass-info', loadChildren: './one-day-pass-info/one-day-pass-info.module#OneDayPassInfoPageModule', resolve: {
      special: DataResolverService
    }
  },
  { path: 'gate-open-close', loadChildren: './pages/gate-open-close/gate-open-close.module#GateOpenClosePageModule' },
  { path: 'redeem-membership', loadChildren: './pages/redeem-membership/redeem-membership.module#RedeemMembershipPageModule' },
  { path: 'gift-membership', loadChildren: './pages/gift-membership/gift-membership.module#GiftMembershipPageModule' },
  { path: 'video-category', loadChildren: './pages/video/video-category/video-category.module#VideoCategoryPageModule' },
  {
    path: 'video-list/:id', loadChildren: './pages/video/video-list/video-list.module#VideoListPageModule', resolve: {
      special: DataResolverService
    }
  },
  {
    path: 'video-play/:id', loadChildren: './pages/video/video-play/video-play.module#VideoPlayPageModule', resolve: {
      special: DataResolverService
    }
  },
  { path: 'user-expired-display', loadChildren: './pages/user-expired-display/user-expired-display.module#UserExpiredDisplayPageModule' },
  { path: 'notification-view-modal', loadChildren: './pages/notification-view-modal/notification-view-modal.module#NotificationViewModalPageModule' },
  { path: 'notification-popup', loadChildren: './pages/notification-popup/notification-popup.module#NotificationPopupPageModule' },
  { path: 'company-not-found', loadChildren: './pages/company-not-found/company-not-found.module#CompanyNotFoundPageModule' },
  { path: 'company-not-enabled', loadChildren: './pages/company-not-enabled/company-not-enabled.module#CompanyNotEnabledPageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
