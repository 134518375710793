import { Pipe, PipeTransform ,Injectable} from '@angular/core';

/**
 * Generated class for the ReplacePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'replace',
})
@Injectable()
export class ReplacePipe implements PipeTransform {


  constructor(){}
  /**
   * Takes a value and makes it replce a string.
   */
  transform(item: any, replace, replacement): any {
    if(item == null) return "";
    item = item.replace(replace, replacement);
    return item;
  }
}




