
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

public CompanyId :number=1104;
     
public APPversion:string="3.1.81";
     
public Apptype:string="ff3";  
     
public identityURL:string="https://ff3idsrvhotfix.fitnessforce.com"; 
     
public MobileAppClientId:string="B2C";
     
public MemberPortalClientId:string= "localhost";
     
public Unisex:boolean=true;   

public clientLogo :string= 'assets/iconff.png';    

public Onboarding:boolean = false;

public askrating :boolean = true;

public askRatingdays:number = 30;

public OnedayPass:boolean = false;

public Giftamembership : boolean = true;

public Reedeemamembership:boolean = true;

public AppointmentFlag:boolean= true;

public workoutvideospecial:boolean = false;

public IsMemberPortalEnabled:boolean =true;

public Gift_Voucher_Redemption_And_Purchase : boolean=false;

public constructor() { }

}

