import { Component, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NetworkErrorService } from 'src/app/services/network-error/network-error.service';
import { debounceTime } from 'rxjs/operators';
import { ModalController, LoadingController } from '@ionic/angular';
import { Network } from '@ionic-native/network/ngx';
import { LanguageService } from '../../services/language/language.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.page.html',
  styleUrls: ['./network-error.page.scss'],
})
export class NetworkErrorPage implements OnInit, OnChanges {

  public loading;
  themeName: string;
  constructor(
    private loadingCtrl: LoadingController,
    private router: Router,
    public translateService: TranslateService,
    public networkService: NetworkErrorService,
    public modalController: ModalController,
    public network: Network,
    public language : LanguageService,
    public theme: ThemeService

  ) { }

  ngOnInit() {
    this.retry();
    this.theme.storedThemetype.then(theme => {
      this.themeName = theme
    })
  }
  ngOnChanges() {

  }


  retry() {
    this.networkService
      .getNetworkStatus()
      .pipe(debounceTime(300))
      .subscribe((connected: boolean) => {
        if (connected) {
         this.closeModal();
        }
        else {
          console.log("retry")
          this.retry();
        }
      });

  }

  closeModal(){
    this.modalController.dismiss();
  }


  openSettings() {
    if ((<any>window).cordova && (<any>window).cordova.plugins.settings) {
      (<any>window).cordova.plugins.settings.open("settings", function () { //OPENED SETTINGS
      },
        function () {
        }
      );
    } else {
    }
  }



  async presentLoading() {
    let waitText
    this.translateService.get('PLEASEWAIT').subscribe(value=>{
      waitText = value;
    })
    this.loading = await this.loadingCtrl.create({
      duration: 1000,
      message: waitText,
    });
    return await this.loading.present();
  }
}
