import { Component, OnInit, Input } from '@angular/core';
import { LoadingController, NavParams, ModalController, AlertController, ToastController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.page.html',
  styleUrls: ['./resume.page.scss'],
})
export class ResumePage implements OnInit {
  @Input() ResumeArr;
  public loading;
  public ResumeDetails;
  resumeAlert: any;
  public toast;
  constructor(
    private router: Router,
    public alertCtrl: AlertController,
    public modalController: ModalController,
    public navParams: NavParams,
    public translateService: TranslateService,
    private loadingCtrl: LoadingController,
    private storage: Storage,
    private HTTPS: HttpService,
    public toastController: ToastController,
    public languageService: LanguageService,
  ) {


    this.ResumeDetails = this.navParams.get('ResumeArr')
    console.log(this.navParams.get('ResumeArr'));
    this.translateService.get('RESUMESUCCESS').subscribe(
      value => {
        this.resumeAlert = value;
      });

  }

  ngOnInit() {
  }


  //loader 
  async presentLoading() {
    let waitText
    this.translateService.get('PLEASEWAIT').subscribe(value => {
      waitText = value;
    })
    this.loading = await this.loadingCtrl.create({
      duration: 1000,
      message: waitText,
    });
    return await this.loading.present();
  }


  backtoMyMembership() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


  async presentToastWithOptions(ToastMessage) {
    this.toast = await this.toastController.create({
      message: ToastMessage,
      position: 'top',
      duration: 3000
    });
    this.toast.present();
  }



  resumenow() {
    this.presentLoading();
    this.storage.get('tocken').then((val) => {
      let tocken = val;
      this.storage.get("langSelect").then((val) => {
        let ISOCODE;
        let cacheval;
        if (val) {
          if (val == "en-US" || val == "en") {
            ISOCODE = "en-US";
          }
          else if (val == "ar-Sa" || val == "ar") {
            ISOCODE = "ar-Sa";
          }
        }
        this.HTTPS.resumeProductPost(this.ResumeDetails, tocken, ISOCODE)
          .subscribe((response) => {
            this.loading.dismiss();
            if (response.data) {
              if (response.data.error) {
                this.presentToastWithOptions(response.data.error)
                this.backtoMyMembership()
              }
              else if (response.data.status) {
                this.translateService.get('RESUMESUCCESS').subscribe(
                  value => {
                    this.resumeAlert = value;
                  });
                this.presentToastWithOptions(this.resumeAlert);
                this.backtoMyMembership()
              }
            }
          });
      })
    })
  }








}
