import { Injectable } from '@angular/core';
import { parsePhoneNumberFromString } from "libphonenumber-js";


@Injectable({
  providedIn: 'root'
})
export class PhoneValidaterService {


  constructor() { }

  isMobileNoValid(defaultCode,phoneNo) {
    let status: boolean;
    let phoneNumber = phoneNo 
    if (defaultCode && phoneNumber.length > 0) {
      phoneNo = (parseInt(phoneNo as string) > 0 ? parseInt(phoneNo) : "").toString();
      const phoneNumber = parsePhoneNumberFromString(
        phoneNo,
        defaultCode.toUpperCase()
      );
      if (phoneNumber && phoneNumber.isValid()) {
        status=true
      }else{
        status=false
      }
    }
    return status;
  }
}
