import { Storage } from '@ionic/storage';
import { HttpService } from 'src/app/services/http/http.service';
import { async } from '@angular/core/testing';
import { Component, OnInit, Input } from '@angular/core';
import { modalController } from '@ionic/core';
import { MenuController, ModalController, LoadingController } from '@ionic/angular';
import { $ } from 'protractor';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-join-waitlist',
  templateUrl: './join-waitlist.page.html',
  styleUrls: ['./join-waitlist.page.scss'],
})
export class JoinWaitlistPage implements OnInit {
    @Input() classDetails: any;
    data: any;
    themeName;

    constructor(private menuCtrl: MenuController, private toastController: ToastController, private modalCtrl: ModalController, private theme: ThemeService,
        private translateService: TranslateService,
    ) { }

    ngOnInit() {
        this.theme.storedThemetype.then(theme => {
            this.themeName = theme
        })
    }

    closeModal() {
        this.modalCtrl.dismiss();
    }

    joinWaitlist() {
        this.modalCtrl.dismiss('join waitlist');
    }

    async presentToast(Messege) {
        const toast = await this.toastController.create({
            message: Messege,
            duration: 2000,
            position: 'bottom'
        });
        toast.present();
    }

}