import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { LanguageService } from 'src/app/services/language/language.service';
import * as moment from 'moment';
import { Location } from '@angular/common';


@Component({
  selector: 'app-gate-open-close',
  templateUrl: './gate-open-close.page.html',
  styleUrls: ['./gate-open-close.page.scss'],
})
export class GateOpenClosePage implements OnInit {

  @Input() access;
  @Input() firstName;
  @Input() LastName;
  @Input() tenantName;

  themeName: any;
  timestamp: string;

  constructor(private route:Router,public alertController: AlertController,public theme: ThemeService,
    public languageService: LanguageService,
    public modalController: ModalController,

    ) { }

    ionViewDidEnter(){
      setTimeout(()=>{
        this.modalController.dismiss();
        this.route.navigate(['/one-day-pass-info']);
      },4000)
    }
  ngOnInit() {

     // if(this.access == 'false'){
    //   this.presentAlertUnsuccessful("Activation Unsuccessful");
    // }
    console.log(this.firstName,'firstname');
    console.log(this.tenantName,'centernameee');
    setTimeout(() => {
      this.theme.storedThemetype.then(theme => {
        console.log(theme)
        this.themeName = theme
      });
    }, 4000)
  }

 
  closeModal(){
    this.modalController.dismiss();
    this.route.navigate(['/one-day-pass-info']);
  }

  // async presentAlertUnsuccessful(message) {
  //   const alert = await this.alertController.create({
  //     header: 'Alert',
  //     message: message,
  //     buttons: [
  //        {
  //         text: 'OK',
  //         handler: () => {
            
  //           console.log('Confirm Okay');
  //         }
  //       }
  //     ]
  //   });
  
  //   await alert.present();
  // }

}
