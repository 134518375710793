
import { GlobalService } from './../../../global.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { CartCouponsService } from '../cart-coupons/cart-coupons.service';
import { IdentityService } from '../identity/identity.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
//import { CompanyId } from '../../config';

declare var apigClientFactory: any;

@Injectable()
export class HttpService {

  httpOptions;
  currentTokenExpiryDate;
  apigclient: any;
  httpParams
  constructor(
    public cartcoupon: CartCouponsService,
    public idp: IdentityService,public alertCtrl: AlertController,
    private http: HttpClient,
    private storage: Storage,
    private translateService : TranslateService,
    private companyDetails: GlobalService
  ) {

    this.httpParams = {
      'Authorization': ''
    }

    this.storage.get('authtoken').then((val) => {
      if (val) {
        this.httpParams.Authorization = "bearer " + val.access_token;
      }
    });

    this.apigclient = apigClientFactory.newClient();
    this.httpOptions = {
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    }
  }


  refreshToken() {
    let promise = new Promise((resolve, reject) => {
      this.idp.refreshtoken().then(val => {
        resolve(val);
      })
    });
    return promise;
  }


  returntocken() {
    this.storage.get('tocken').then((val) => {
    })
  }

  generateNewToken() {
  }

  checkTokenValidity(): Promise<boolean> {

    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      this.storage.get('authtoken').then((val) => {
        if(val)
        {
          this.httpParams.Authorization = val.access_token;
          let expiry = Number(val.receivedToken.expires_in);
       
        
       
        this.currentTokenExpiryDate = moment(val.timestamp, "YYYY-MM-DD HH:mm ss SSS").add(expiry, 'seconds');
        if (this.currentTokenExpiryDate) {
          let currentTime = moment();
          if (currentTime.isBefore(this.currentTokenExpiryDate)) {
            // return Promise.resolve(true);
           // console.log("Bef expry",currentTime.toDate(),this.currentTokenExpiryDate.toDate(),expiry)
            resolve(true)
          }
          else {
            //return Promise.resolve(false);
            this.refreshToken().then(v => {
              if (v) {
                resolve(false)
              }

            });
            //return false;
          }
        }
      }
        else {
          // return Promise.resolve(false);
          resolve(false);
        }
      });

     
    });
    return promise;
  }


  nullEliminate(obj) {
    if (Array.isArray(obj)) {
      obj.map(a => {
        if (a) {
          a = this.nullEliminate(a);
        }
        else {
          a = "";
        }
      })
    }
    else
      if (typeof (obj) == "object") {
        for (var prop in obj) {
          if (Array.isArray(obj[prop])) {

            if (obj[prop].includes(null)) {
              for (let i = 0; i < obj[prop].length; i++) {
                if (obj[prop][i] == null) {
                  obj[prop][i] = "";
                }
              }
            }
            obj[prop].map(a => {
              if (a) {
                a = this.nullEliminate(a);
              }
              else {
                a = "";
              }
            }
            );
          }
          else {
            if (!obj[prop]) {
              obj[prop] = "";
            }
          }
        }
      }
    return obj;
  }

  // Updatedivices(appDetails): Observable<any> {
  //   let result:any;
  //   let params:any;
  //   params={
  //     'deviceType':appDetails.deviceType,
  //     'clientId':appDetails.clientId,
  //     'currentVersion':appDetails.currentVersion
  //   }
  //   let Updates= this.apigclient.otpGetdeviceupdatetypeGet(params)
  //   .then(responese=>{
  //      console.log(responese,'llllllll')
  //     result=this.nullEliminate(responese);
  //     return result;
  
  //   })
  //   .catch(error=>{
  //     console.log(error,'ooooo')
  //   });
  //   return from(Promise.resolve(Updates))
  //  }

  Updatedevices(appDetails): Observable<any> {
    let result:any;
    let params:any;
    params={
      'deviceType': appDetails.deviceType,
      'clientId': appDetails.clientId,
      'currentVersion': appDetails.currentVersion,
      'companyId': this.companyDetails.CompanyId
    }
    let Updates= this.apigclient.otpV2GetdeviceupdatetypeGet(params)
    .then(responese=>{
      result=this.nullEliminate(responese);
      return result;
  
    })
    .catch(error=>{
      console.log(error,'ooooo')
    });
    return from(Promise.resolve(Updates))
   }


  //**********LOGIN FLOW************//

  //LOGIN PAGE [MEMBER DETAILS]
  LoginGetMemberDetails(memberDetailsRequestBody): Observable<any> {
    let results;
    let params = {
      'countrycode': memberDetailsRequestBody.countrycode,
      'mobileno': memberDetailsRequestBody.mobileno,
      'companyid': memberDetailsRequestBody.companyid
    }


    let memberDetails = this.apigclient.otpMemberdetailsbymobilenoGet(params)
      .then(response => {
        results = this.nullEliminate(response);
        return results;
      })
      .catch(async(error) =>{
        let multipleCenterText
            this.translateService.get('MULTIPLECENTER').subscribe(value => {
              multipleCenterText = value;
            })

            let invalidText
            this.translateService.get('INVALID').subscribe(value => {
              invalidText = value;
            })

            let okText
            this.translateService.get('OK').subscribe(value => {
              okText = value;
            })

        if(error.data.error.status == 500 && error.data.error.message) {
          const alert = await this.alertCtrl.create({
            header: invalidText,
            message: multipleCenterText,
            buttons: [okText],
          });
          alert.present();
        } else { const alert = await this.alertCtrl.create({
          header: multipleCenterText,
          buttons: [okText],
        }); 
           alert.present();
      }
        return error
      });
    return from(Promise.resolve(memberDetails));
  }

  //SIGN UP TERMS EULA PAGE [COMPANY DETAILS]
  SignUpTermsEulaGetCompanyDetails(companyDetailsRequestBody, tocken, otpCompaniesIdCacheObj): Observable<any> {
    //return Observable.fromPromise(this.login.getCompanyDetails(companyDetailsRequestBody, this.httpOptions));
    let companyDetails = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
          'lang_isocode': otpCompaniesIdCacheObj.lang_isocode,
          'CompanyID': otpCompaniesIdCacheObj.CompanyID,
          'BillMembershipCachebuster': otpCompaniesIdCacheObj.BillMembershipCachebuster,
          'schedulecachebuster': otpCompaniesIdCacheObj.schedulecachebuster
        }
        let results;
        return this.apigclient.companiesIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
            'lang_isocode': otpCompaniesIdCacheObj.lang_isocode,
            'CompanyID': otpCompaniesIdCacheObj.CompanyID,
            'BillMembershipCachebuster': otpCompaniesIdCacheObj.BillMembershipCachebuster,
            'schedulecachebuster': otpCompaniesIdCacheObj.schedulecachebuster
          }
          let results;
          return this.apigclient.companiesIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })

    return from(Promise.resolve(companyDetails));


  }
  //OTP PAGE [OTP RESPONSE]
  OTPGetOtpResponse(otpRequestBody, tocken): Observable<any> {

    let body = {
      "centerid": otpRequestBody.centerid,
      "companyid": otpRequestBody.companyid,
      "mobilenumber": otpRequestBody.mobilenumber,
      "memberid": otpRequestBody.memberid,
      "source": "mobileapp",
      "isuser": "false",
      "waiverinfo": ""
    }

    let otpResponse = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken

        }

        let results;
        return this.apigclient.otpSendPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val

          }

          let results;
          return this.apigclient.otpSendPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })

    return from(Promise.resolve(otpResponse));


  }

  //OTP PAGE [OTP VERIFIED]
  OTPGetOtpVerified(otpVerificationRequestBody, tocken): Observable<any> {
    let otpVerified = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
        }

        //return Observable.fromPromise(this.login.getOtpVerified(otpVerificationRequestBody, this.httpOptions));
        let results;
        return this.apigclient.otpVerifyPost(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
          }

          //return Observable.fromPromise(this.login.getOtpVerified(otpVerificationRequestBody, this.httpOptions));
          let results;
          return this.apigclient.otpVerifyPost(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })

    return from(Promise.resolve(otpVerified));


  }

  //----------------BECOME MEMBER POST REQUEST------------------//
  LoginBecomeMember(becomeMemberPostRequestBody): Observable<any> {
    if (this.checkTokenValidity()) {
      let results;
      let params = {
        'trackMode': 'mobileapp',
        'firstName': becomeMemberPostRequestBody.firstName,
        'lastName': becomeMemberPostRequestBody.lastName,
        'emailAddress': becomeMemberPostRequestBody.emailAddress,
        'dialcode': becomeMemberPostRequestBody.dialcode,
        'mobileno': becomeMemberPostRequestBody.mobileno,
        'gender': becomeMemberPostRequestBody.gender,
        'tenantDetails': becomeMemberPostRequestBody.tenantDetails
      }
      let becomeMemberDetails = this.apigclient.membersIdPost(params)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
      return from(Promise.resolve(becomeMemberDetails));
    }
    else {
      return from(Promise.resolve(Error('Token Expired')));
    }
  }

  //--------------CLIENT PROFILE PUT REQUEST--------------------------//
  DashboardPutClientProfile(_data: any, tocken: string): Observable<any> {    
    let clientProfilePut = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params = {
          'Authorization': tocken
        }
        let body = {
          'trackMode': 'mobileapp',
          'emergencyContactName': _data.emergencyContactName,
          'emergencyContactNumber': _data.emergencyContactNumber,
          //'dob': _data.dob,
          'nationality': _data.nationality,
          'nationalOrIqama': _data.nationalOrIqama,
          'nationalid': _data.nationalid
          //'emailAddress': _data.emailAddress
        }
        return this.apigclient.membersIdPut(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch( response => {
            return response;
          }
          );
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results: any;
          let params = {
            'Authorization': val
          }
          let body = {
            'trackMode': 'mobileapp',
            'emergencyContactName': _data.emergencyContactName,
            'emergencyContactNumber': _data.emergencyContactNumber,
            'dob': _data.dob,
            'nationality': _data.nationality,
            'nationalOrIqama': _data.nationalOrIqama,
            'nationalid': _data.nationalid,
            'emailAddress': _data.emailAddress
          }
          // return this.apigclient.membersIdPut(params, body)
          //   .then(response => {
          //     results = this.nullEliminate(response);
          //     console.log(results, 'huh')
          //     return results;
          //   })
          //   .catch();
        })
      }
    })
    return from(Promise.resolve(clientProfilePut));
  }


  DashboardPutClientProfileisoUpdate(iso: any, tocken: string): Observable<any> {    
    let clientProfilePut = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params = {
          'Authorization': tocken
        }
        let body = {
          'trackMode': 'mobileapp',
          'isocode':iso
        }
        return this.apigclient.membersIdPut(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch( response => {
            return response;
          }
          );
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results: any;
          let params = {
            'Authorization': val
          }
          let body = {
            'trackMode': 'mobileapp',
            'isocode':iso
          }
          // return this.apigclient.membersIdPut(params, body)
          //   .then(response => {
          //     results = this.nullEliminate(response);
          //     console.log(results, 'huh')
          //     return results;
          //   })
          //   .catch();
        })
      }
    })
    return from(Promise.resolve(clientProfilePut));
  }

  //*******Upload Documents******//
  UploadDocuments(_docData:any,tocken:string): Observable<any>
  {
    let clientProfilePut = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params = {
          'Authorization': tocken
        }
        
        return this.apigclient.membersIdUploadformsPost(params, _docData)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch( response => {
            return response;
          }
          );
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results: any;
          let params = {
            'Authorization': val
          }
          let body = {
            
          }
          // return this.apigclient.membersIdPut(params, body)
          //   .then(response => {
          //     results = this.nullEliminate(response);
          //     console.log(results, 'huh')
          //     return results;
          //   })
          //   .catch();
        })
      }
    })
    return from(Promise.resolve(clientProfilePut));
  }

  //**********CLUB FLOW************//

  //CLUB LOCATOR PAGE [CLUB LIST]
  ClubLocatorGetClubLocations(clubBody, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'companyID': clubBody.companyID,
        }
        return this.apigclient.centersGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'companyID': clubBody.companyID,
          }
          return this.apigclient.centersGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerlist));


  }

  //CLUB DETAILS PAGE [CLUB DETAILS]
  ClubDetailsGetClubDetails(tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken
        }
        return this.apigclient.centersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val
          }
          return this.apigclient.centersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerlist));

  }

  //**********DASHBOARD FLOW************//

  //DASHBOARD PAGE [PROFILE DETAILS]
  DashboardGetClientProfile(tocken): Observable<any> {
    let clientProfile = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params;

        params = {
          'Authorization': tocken

        }
        return this.apigclient.membersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();

      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params;

          params = {
            'Authorization': val

          }
          return this.apigclient.membersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })



    return from(Promise.resolve(clientProfile));


  }
  //**********OFFERS FLOW************//

  //OFFER CARD DASHBOARD PAGE [OFFER DETAILS]
  OfferCardDashboardGetOfferDetails(tck, offersCacheObj): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tck,
          'lang_isocode': offersCacheObj.lang_isocode,
          'MemberID': offersCacheObj.MemberID,
          'CenterID': offersCacheObj.CenterID,
          'BillMembershipCachebuster': offersCacheObj.BillMembershipCachebuster,
          'schedulecachebuster': offersCacheObj.schedulecachebuster
        }
        return this.apigclient.offersGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'lang_isocode': offersCacheObj.lang_isocode,
            'MemberID': offersCacheObj.MemberID,
            'CenterID': offersCacheObj.CenterID,
            'BillMembershipCachebuster': offersCacheObj.BillMembershipCachebuster,
            'schedulecachebuster': offersCacheObj.schedulecachebuster
          }
          return this.apigclient.offersGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })
    return from(Promise.resolve(trainerlist));
  }

  withoutTokenOfferListIntermidiate(body, offerByOtpCache) {
    if (this.checkTokenValidity()) {
      let results;
      let params = {
        "centerid": body.centerid,
        "lang_isocode": "en-us"
      }
      let clientProfile = this.apigclient.otpOffersGet(params)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
      return Promise.resolve(clientProfile);
    }
    else {
      return from(Promise.resolve(Error('Token Expired')));
    }
  }


  // feedbackPostFeedback(feedback, tocken) {
  //   let tocken1 = "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjU5QTJDNTlFQTc2REM2NzZDQUEwRTExMzFGOEIwRDgxOEYyMEY5NTYiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJXYUxGbnFkdHhuYktvT0VUSDRzTmdZOGctVlkifQ.eyJuYmYiOjE1Njg1MzU1MTcsImV4cCI6MTU3MTEyNzUxNywiaXNzIjoiaHR0cHM6Ly9pZHNydmNsb25lLmZpdG5lc3Nmb3JjZS5jb20iLCJhdWQiOlsiaHR0cHM6Ly9pZHNydmNsb25lLmZpdG5lc3Nmb3JjZS5jb20vcmVzb3VyY2VzIiwiRkZXZWJBUElWMiJdLCJjbGllbnRfaWQiOiJCMkMiLCJzdWIiOiIxNTIwNDQ1NzIzfjk5OX4yMTQyfmVuLVVTIiwiYXV0aF90aW1lIjoxNTY4NDQzNTY0LCJpZHAiOiJsb2NhbCIsImNvbXBhbnlpZCI6Ijk5OSIsImNlbnRlcmlkIjoiMjE0MiIsIm1lbWJlcmlkIjoiMTUyMDQ0NTcyMyIsIm1lbWJlcm5hbWUiOiJzb25hbCBzaW5naCIsIm1lbWJlcmVtYWlsIjoicHJhbmFsaWNAZml0bmVzc2ZvcmNlLmNvbSIsImVucXVpcnljZW50ZXIiOiIyMDAzIiwiaXNvY29kZSI6ImVuLVVTIiwicHJlZml4IjoiTkxGTCIsInRpbWV6b25lIjoiQXJhYiBTdGFuZGFyZCBUaW1lIiwiY3VycmVuY3kiOiJTQVIiLCJhZG1pbnVzZXJpZCI6IjEiLCJhZG1pbnVzZXJuYW1lIjoiYWRtaW4iLCJzdWJkb21haW4iOiJsZWVqYW1zdGFnaW5nLmZpdG5lc3Nmb3JjZS5jb20iLCJtb2JpbGVubyI6IjExMTIyMjk5OSIsImNvdW50cnljb2RlIjoiOTY2Iiwic2NvcGUiOlsib3BlbmlkIiwiRkZXZWJBUElWMiIsIm9mZmxpbmVfYWNjZXNzIl0sImFtciI6WyJwd2QiXX0.G5tdxvScPoSv-Vu2KfokQfB4J5s4Le6KRFpcgY4IuDr4Lwbwi3f4WNdCEEpJnYW3l8CBVBW44P4K459ZCMchkL2XoH2La3Jv4FEMMRs8ROdFpxlS6tBt41PDt_THYzBnURQXVW08FjQsubIQMtiOh636l6VX7zzCwWeAfVEQfg-CGba0sf9xBnO_J6j-nCmLvn0JB67EWMc3VwtLTURbVX0zHc3rhB0_p6E6Fl2uallvvl4Z54BwXNHXOCq7WEt5OHAedUMSMV6jfqGOgYHKu7CVAZvo4jI3Y8Qq7KFySY3b2cwCpA4RbuXv_zkr4cUNn6ivH3Ek7OSYq5qS5lOzDA"

  //    let params = {
  //      'Authorization': tocken1,
  //    }
  //         let  body = {
  //            'feedbackText': feedback.feedbackText,
  //            'emails': feedback.emails,
  //            'ContactNo': feedback.ContactNo,
  //            'feedbackImage':feedback.feedbackImage,
  //            'UserEmail': feedback.UserEmail 
  //          };

  //          return this.apigclient.membersIdFeedbackPost(params, body)
  //            .then(response => {
  //                console.log(response,"from")

  //            })
  //  }


  feedbackPostFeedback(feedback: any, tocken: string): Observable<any> {


    let feedback1 = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        // let body: any;
        
        let  body = {
          'feedbackText': feedback.feedbackText,
          'emails': feedback.emails,
          'ContactNo': feedback.ContactNo,
          'feedbackImage':feedback.feedbackImage,
          'UserEmail': feedback.UserEmail 
        };

        let params = {
          'Authorization': tocken,
        }
 
        return this.apigclient.membersIdFeedbackPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          // let body;

          let  body = {
            'feedbackText': feedback.feedbackText,
            'emails': feedback.emails,
            'ContactNo': feedback.ContactNo,
            'feedbackImage':feedback.feedbackImage,
            'UserEmail': feedback.UserEmail 
          };

          let params = {
            'Authorization': val,
          }

      

          return this.apigclient.membersIdFeedbackPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })

    return from(Promise.resolve(feedback1));
  }


  GetOfferDetailsByOfferId(offerID: string, tocken: string, offersByIdCache: any): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'Campaignid': offerID,
          'lang_isocode': offersByIdCache.lang_isocode,
          'MemberID': offersByIdCache.MemberID,
          'BillMembershipCachebuster': offersByIdCache.schedulecachebuster,
          'schedulecachebuster': offersByIdCache.schedulecachebuster,
        }

        return this.apigclient.offersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'Campaignid': offerID,
            'lang_isocode': offersByIdCache.lang_isocode,
            'MemberID': offersByIdCache.MemberID,
            'BillMembershipCachebuster': offersByIdCache.schedulecachebuster,
            'schedulecachebuster': offersByIdCache.schedulecachebuster,
          }

          return this.apigclient.offersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })



    return from(Promise.resolve(trainerlist));


  }


  v2GetOfferDetailsByOfferId(offerID: string, tocken: string, offersByIdCache: any): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'centerID': offersByIdCache.CenterID,
          'isoCode': offersByIdCache.lang_isocode,
          'Authorization': tocken,
          'Campaignid': offerID,
          'lang_isocode': offersByIdCache.lang_isocode,
          'MemberID': offersByIdCache.MemberID,
          'BillMembershipCachebuster': offersByIdCache.BillMembershipCachebuster,
          'schedulecachebuster': offersByIdCache.schedulecachebuster,
        }
        return this.apigclient.v2OffersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'centerID': '',
            'isoCode': '',
            'Authorization': val,
            'Campaignid': offerID,
            'lang_isocode': offersByIdCache.lang_isocode,
            'MemberID': offersByIdCache.MemberID,
            'BillMembershipCachebuster': offersByIdCache.BillMembershipCachebuster,
            'schedulecachebuster': offersByIdCache.schedulecachebuster,
          }
          return this.apigclient.v2OffersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })
    return from(Promise.resolve(trainerlist));
  }

  v2OfferDetailsGetOfferById(Data, tocken, offersByIdCache, centerID, isoCode): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'centerID': centerID,
          'isoCode': isoCode,
          'Authorization': tocken,
          'Campaignid': Data,
          'lang_isocode': offersByIdCache.lang_isocode,
          'MemberID': offersByIdCache.MemberID,
          'BillMembershipCachebuster': offersByIdCache.BillMembershipCachebuster,
          'schedulecachebuster': offersByIdCache.schedulecachebuster,
        }

        return this.apigclient.v2OffersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'centerID': '',
            'isoCode': '',
            'Authorization': val,
            'Campaignid': Data,
            'lang_isocode': offersByIdCache.lang_isocode,
            'MemberID': offersByIdCache.MemberID,
            'BillMembershipCachebuster': offersByIdCache.BillMembershipCachebuster,
            'schedulecachebuster': offersByIdCache.schedulecachebuster,
          }

          return this.apigclient.v2OffersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })



    return from(Promise.resolve(trainerlist));

  }


  //**********BUY MEMBERSHIP FLOW************//

  //BUY MEMBERSHIP PAGE [MEMBERSHIP PRODUCTS]
  BuyMainGetMembershipDetails(tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params;


        params = {
          'Authorization': tocken

        }
        return this.apigclient.productsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params;


          params = {
            'Authorization': val

          }
          return this.apigclient.productsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })




    return from(Promise.resolve(trainerlist));


  }




  BuyMainGetProducts(tocken, centerID, isoCode): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        if (isoCode) {
          if (isoCode == "en") {
            isoCode = "en-US";
          }
          else
            if (isoCode == "ar") {
              isoCode = "ar-sa";
            }
        }
        let results;
        let params;


        params = {
          'Authorization': tocken,
          'centerID': centerID,
          'isoCode': isoCode

        }
        return this.apigclient.v2ProductsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params;


          params = {
            'Authorization': tocken,
            'centerID': centerID,
            'isoCode': isoCode

          }
          return this.apigclient.v2ProductsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })




    return from(Promise.resolve(trainerlist));


  }


  // ********************** my new membership *************************** //
  resumeProductPost(obj, tocken, ISOCODE): Observable<any> {

    let remumeproduct = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'isoCode': ISOCODE
        }
        let body =
        {
          "FreezeId": obj.FreezeId,
          "Comments": obj.Comments,
          "Type": obj.Type,
          'isoCode': ISOCODE
        }


        return this.apigclient.membersIdStopfreezePost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'isoCode': ISOCODE
          }
          let body =
          {
            "FreezeId": obj.FreezeId,
            "Comments": obj.Comments,
            "Type": obj.Type,
            'isoCode': ISOCODE
          }

          return this.apigclient.membersIdStopfreezePost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(remumeproduct));

  }


  //chitranjan cancell freeze new change the end point as per apig client

  CancelProductFreezePost(obj, tocken, ISOCODE): Observable<any> {

    let remumeproduct = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'isoCode': ISOCODE
        }
        let body =
        {
          "FreezeId": obj.FreezeId,
          "Comments": obj.Comments,
          "Type": obj.Type,
          'isoCode': ISOCODE
        }

        return this.apigclient.changeAsPerApig(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'isoCode': ISOCODE
          }
          let body =
          {
            "FreezeId": obj.FreezeId,
            "Comments": obj.Comments,
            "Type": obj.Type,
            'isoCode': ISOCODE
          }

          return this.apigclient.changeAsPerApig(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(remumeproduct));

  }


  //  chitranjan cancell freeze change freeze date the end point as per apig client

  ChangeFreezeDatePost(obj, tocken, ISOCODE): Observable<any> {

    let remumeproduct = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'isoCode': ISOCODE
        }
        let body =
        {
          "FreezeId": obj.FreezeId,
          "Comments": obj.Comments,
          "Type": obj.Type,
          'isoCode': ISOCODE
        }

        return this.apigclient.changeAsPerApig(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'isoCode': ISOCODE
          }
          let body =
          {
            "FreezeId": obj.FreezeId,
            "Comments": obj.Comments,
            "Type": obj.Type,
            'isoCode': ISOCODE
          }

          return this.apigclient.changeAsPerApig(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(remumeproduct));

  }


  /* freeze overlapping date */

  freezeBillingOverlapPost(freezeobj: any, tocken: string, isoCode: string): Observable<any> {
    let freezeOverlapDate = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'lang_isocode': isoCode,
          'isoCode': isoCode
        }
        let body =
        {
          "membershipID": freezeobj.membershipID,
          "centerID": freezeobj.centerID,
          "comments": freezeobj.comments,
          "fromDate": freezeobj.fromDate,
          "toDate": freezeobj.toDate,
          "additionalPaymentID": freezeobj.additionalPaymentID,
          "activationDate": freezeobj.activationDate,
          "payment": freezeobj.payment,
          'isoCode': isoCode,
          "trackMode": freezeobj.trackMode
        }
        return this.apigclient.billingValidatefreezePost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch(e => {
            return e;
          });
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'lang_isocode': isoCode,
            'isoCode': isoCode
          }

          let body =
          {
            "membershipID": freezeobj.membershipID,
            "centerID": freezeobj.centerID,
            "comments": freezeobj.comments,
            "fromDate": freezeobj.fromDate,
            "toDate": freezeobj.toDate,
            "additionalPaymentID": freezeobj.additionalPaymentID,
            "activationDate": freezeobj.activationDate,
            "payment": freezeobj.payment,
            "trackMode": freezeobj.trackMode,
            'isoCode': isoCode
          }

          return this.apigclient.billingValidatefreezePost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });
        });
      }
    })

    return from(Promise.resolve(freezeOverlapDate));
  }


  /* end of freeze overlapping date */












  //**********PROFILE FLOW************//

  //PROFILE PAGE [PROFILE DETAILS]
  //PROFILE PAGE [PROFILE DETAILS]
  // profileGetProfileDetails(tocken): Observable<any> {
  //   if(this.checkTokenValidity())
  //   {
  //     let profilelist;
  //   let results;
  //   let 
  // params={
  //       'Authorization': tocken

  //     }

  //   profilelist = this.apigclient.membersIdGet(params)
  //     .then(response => {
  //       results = this.nullEliminate(response);
  //       console.log(response, 'inter')
  //       return results;
  //     })
  //     .catch();

  //   return Promise.resolve(profilelist);
  // }
  // else
  // {
  //   return Promise.resolve(Error('Token Expired')); 
  // }
  // }



  profileGetProfileDetails(tocken, memberIdCacheObj): Observable<any> {
    let profilelist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'lang_isocode': memberIdCacheObj.lang_isocode,
          'MemberID': memberIdCacheObj.MemberID,
          'BillMembershipCachebuster': memberIdCacheObj.BillMembershipCachebuster,
          'schedulecachebuster': memberIdCacheObj.schedulecachebuster
        }
        return this.apigclient.membersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'lang_isocode': memberIdCacheObj.lang_isocode,
            'MemberID': memberIdCacheObj.MemberID,
            'BillMembershipCachebuster': memberIdCacheObj.BillMembershipCachebuster,
            'schedulecachebuster': memberIdCacheObj.schedulecachebuster

          }
          return this.apigclient.membersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(profilelist));

  }

  profileV2GetProfileDetails(tocken,centerid,Isocode): Observable<any> {
    let profilelist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'centerId': centerid,
          'isoCode': Isocode,
          'Authorization': tocken,
        }
        return this.apigclient.v2MembersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch(e => {
            return e
          });
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'centerId': centerid,
            'isoCode': Isocode,

          }
          return this.apigclient.v2MembersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(profilelist));

  }

  // //PROFILE PAGE [MEMBERSHIPLIST DETAILS]
  // profileGetMembershipDetails(tocken,memberIdMembershipsCacheObj): Observable<any> {
  //   let membershiplist = this.checkTokenValidity().then(T => {
  //     if (T) {
  //       let results;
  //       let params = {
  //         'Authorization': tocken,
  //         'lang_isocode':memberIdMembershipsCacheObj.lang_isocode ,
  //         'MemberID': memberIdMembershipsCacheObj.MemberID,
  //         'BillMembershipCachebuster': memberIdMembershipsCacheObj.BillMembershipCachebuster,
  //         'schedulecachebuster': memberIdMembershipsCacheObj.schedulecachebuster
  //       }

  //       return this.apigclient.membersIdMembershipsGet(params)
  //         .then(response => {
  //           results = this.nullEliminate(response);
  //           console.log(response, 'profile membershipDetails')
  //           return results;
  //         })
  //         .catch();
  //     }
  //     else {
  //       return this.storage.get('tocken').then((val) => {

  //         let results;
  //         let params = {
  //           'Authorization': val,
  //           'lang_isocode':memberIdMembershipsCacheObj.lang_isocode ,
  //           'MemberID': memberIdMembershipsCacheObj.MemberID,
  //           'BillMembershipCachebuster': memberIdMembershipsCacheObj.BillMembershipCachebuster,
  //           'schedulecachebuster': memberIdMembershipsCacheObj.schedulecachebuster
  //         }

  //         return this.apigclient.membersIdMembershipsGet(params)
  //           .then(response => {
  //             results = this.nullEliminate(response);
  //             console.log(response, 'profile membershipDetails')
  //             return results;
  //           })
  //           .catch();

  //       })
  //     }
  //   })



  //   return Promise.resolve(membershiplist);

  // }

  //PROFILE PAGE [MEMBERSHIPLIST DETAILS]
  profileGetMembershipDetails(tocken, memberIdMembershipsCacheObj, IsoCode): Observable<any> {
    let membershiplist = this.checkTokenValidity().then(T => {
      if (T) {



        let results;
        let params = {
          'Authorization': tocken,
          'lang_isocode': IsoCode,
          'MemberID': memberIdMembershipsCacheObj.MemberID,
          'BillMembershipCachebuster': memberIdMembershipsCacheObj.BillMembershipCachebuster,
          'schedulecachebuster': memberIdMembershipsCacheObj.schedulecachebuster
        }

        return this.apigclient.v2MembersIdMembershipsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'lang_isocode': IsoCode,
            'MemberID': memberIdMembershipsCacheObj.MemberID,
            'BillMembershipCachebuster': memberIdMembershipsCacheObj.BillMembershipCachebuster,
            'schedulecachebuster': memberIdMembershipsCacheObj.schedulecachebuster
          }

          return this.apigclient.v2MembersIdMembershipsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })



    return from(Promise.resolve(membershiplist));

  }

  specialFreeze(tocken,IsoCode): Observable<any> {
    let membershiplist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken
        }
        return this.apigclient.membersIdGetspecialfreezeinfoGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val
          }
          return this.apigclient.membersIdGetspecialfreezeinfoGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })
    return from(Promise.resolve(membershiplist));
  }

  //PROFILE PAGE [MEMBERATTENDANCE DETAILS]
  profileGetMemberAttendanceDetails(body, tocken): Observable<any> {
    let memberAttendance = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'month': body.month
        }
        return this.apigclient.membersIdCheckinsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'month': body.month
          }
          return this.apigclient.membersIdCheckinsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })



    return from(Promise.resolve(memberAttendance));

  }


  // //PROFILE PAGE [Member Weight]
  // profileGetMemberWeight(): Observable<any> {
  //   if(this.checkTokenValidity())
  //   {
  //   let results;
  //   let params={
  //     'Authorization': this.httpParams.Authorization

  //   }
  //   let memberweight = this.apigclient.membersIdGetWeight(params)
  //     .then(response => {
  //       results = this.nullEliminate(response);
  //       console.log(response, 'inter')
  //       return results;
  //     })
  //     .catch();
  //   return Promise.resolve(memberweight);
  // }
  // else
  // {
  //   return Promise.resolve(Error('Token Expired')); 
  // }
  // }


  //**********CART FLOW************//

	  //CART COUPONS [COUPONS DETAILS]
  CartCouponsGetCouponsDetails(tocken): Observable<any> {
    return from((this.cartcoupon.getAllCouponsDetails(this.httpOptions)));
  }

  //CART PAGE [TAX DETAILS V2]  
  GetTAXlistV2(taxobj, tocken): Observable<any> {
    let taxlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'centerID': taxobj.centerID,
          'ProductIds': taxobj.ProductIds,
          'AddtionPaymentIds': taxobj.AddtionPaymentIds,
          'isoCode': taxobj.isocode
        }
        let body = {


        }


        return this.apigclient.v2ProductsTaxesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();

      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': tocken,
            'centerID': taxobj.centerID,
            'ProductIds': taxobj.ProductIds,
            'AddtionPaymentIds': taxobj.AddtionPaymentIds,
            'isoCode': taxobj.isocode
          }
          let body = {
            'centerID': taxobj.centerID,
            'ProductIds': taxobj.ProductIds,
            'AddtionPaymentIds': taxobj.AddtionPaymentIds,
            'isoCode': taxobj.isocode

          }
          return this.apigclient.v2ProductsTaxesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })



    return from(Promise.resolve(taxlist));


  }






  //CART PAGE [TAX DETAILS]  
  GetTAXlist(ids, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'ProductIds': ids

        }

        return this.apigclient.productsTaxesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();

      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'ProductIds': ids

          }

          return this.apigclient.productsTaxesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })



    return from(Promise.resolve(trainerlist));


  }

  //**********PAYMENT FLOW************//

  //**********PURCHASE HISTORY FLOW************//

  //PURCHASE HISTORY PAGE [PURCHASE DETAILS]
  PurchaseHistoryGetPurchaseDetailsById(ID, tocken, invoiceByIdCache): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;

        let params;



        params = {
          'Authorization': tocken,
          'InvoiceId': ID,
          'lang_isocode': invoiceByIdCache.lang_isocode,
          'MemberID': invoiceByIdCache.MemberID,
          'BillMembershipCachebuster': invoiceByIdCache.BillMembershipCachebuster
        }
        return this.apigclient.invoicesIdGet(params, tocken)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;

          let params;



          params = {
            'Authorization': val,
            'InvoiceId': ID,
            'lang_isocode': invoiceByIdCache.lang_isocode,
            'MemberID': invoiceByIdCache.MemberID,
            'BillMembershipCachebuster': invoiceByIdCache.BillMembershipCachebuster
          }
          return this.apigclient.invoicesIdGet(params, tocken)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })
    return from(Promise.resolve(trainerlist));
  }


  /*
    PurchaseHistoryPostInvoiceByEmail(emailBody,tocken): Observable<any> {
      if(this.checkTokenValidity())
      {
        let results;
        let trainerlist;
        let params;
     params={
            'Authorization': tocken,
            'invoiceid':emailBody.invoiceid, 
            'email':""
          
         
          }
        trainerlist = this.apigclient.invoicesSendinvoiceemailPost(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
  
  
        return Promise.resolve(trainerlist);
    }
    else
    {
      return Promise.resolve(Error('Token Expired')); 
    }
    }
    */


  PurchaseHistoryPostInvoiceByEmail(emailBody, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
        }
        let body = {
          'invoiceid': emailBody.invoiceid,
          'email': emailBody.email
        }
        return this.apigclient.invoicesSendinvoiceemailPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
          }
          let body = {
            'invoiceid': emailBody.invoiceid,
            'email': emailBody.email
          }
          return this.apigclient.invoicesSendinvoiceemailPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerlist));
  }




  PurchaseHistoryPostInvoicePDF(pdfBody, tocken,isoCode): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
        }
        let body = {
          'invoiceid': pdfBody.invoiceid,
          'email': pdfBody.email

        }

        return this.apigclient.invoicesGenerateinvoicepdfPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
          }
          let body = {
            'invoiceid': pdfBody.invoiceid,
            'email': pdfBody.email

          }

          return this.apigclient.invoicesGenerateinvoicepdfPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(trainerlist));

  }

  PurposesGet(toc, isoCode, companyId): Observable<any> {
    let trainerdetail = this.checkTokenValidity().then().then(T => {
      let body = {};
      if (T) {

        let params = {
          'Authorization': toc,
          'isoCode': isoCode,
          'companyId': companyId
        }

        return this.apigclient.purposesGet(params, body)
          .then(response => {
            return response;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let params = {
            'Authorization': toc,
            'isoCode': isoCode,
            'CenterId': companyId
          }
          return this.apigclient.purposesGet(params, body)
            .then(response => {
              return response;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerdetail));

  }

  //DATETIME PAGE [SLOTS DETAILS]
  dateTimeGetBookedSlotsDetails(date, trainerid, tocken): Observable<any> {

    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
          'trainerID': trainerid,
          'date': date
        }
        let results;
        return this.apigclient.instructorsBookedslotsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      } else {
        return this.storage.get('tocken').then((val) => {

          let params = {
            'Authorization': tocken,
            'trainerID': trainerid,
            'date': date
          }
          let results;
          return this.apigclient.instructorsBookedslotsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }




    });
    return from(Promise.resolve(trainerlist));
  }



  //DATETIME PAGE [SLOTS DETAILS]
  dateTimeGetAvailableSlotsDetails(date, trainerid, tocken): Observable<any> {

    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
          'trainerID': trainerid,
          'date': date
        }
        let results;
        return this.apigclient.instructorsAvailabledslotsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();

      } else {
        return this.storage.get('tocken').then((val) => {

          let params = {
            'Authorization': tocken,
            'trainerID': trainerid,
            'date': date
          }
          let results;
          return this.apigclient.instructorsAvailabledslotsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    });
    return from(Promise.resolve(trainerlist));

  }




  CancelAppointmentDelete(toc,body): Observable<any> 
  {  
    let trainerlist = this.checkTokenValidity().then(T => {

      if (T) {
        let results;
        let params = {
          'Authorization': toc

        }
        return this.apigclient.appointmentDelete(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val
          }
          return this.apigclient.appointmentDelete(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            }).catch();
        });
      }

    })

    return from( Promise.resolve(trainerlist));

  }

  AppointmentReasonsGet(toc,isoCode): Observable<any> 
  {  
    let trainerlist = this.checkTokenValidity().then(T => {

      if (T) {
        let results;
        let params = {
          'Authorization': toc,
          'lang_code':isoCode,

        }


        return this.apigclient.appointmentCancelreasonGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'lang_code':isoCode,

          }

          return this.apigclient.appointmentCancelreasonGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }

    })

    return from( Promise.resolve(trainerlist));

  }


  AppointmentEditPutRequest(clientProfilePutRequestBody, tocken): Observable<any> {
    let clientProfilePut = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
  
        let params = {
          'Authorization': tocken
        }
        let body = {
           "xref_ScheduleId":clientProfilePutRequestBody.xref_ScheduleId,
           "StartDate": clientProfilePutRequestBody.StartDate,
           "EndDate": clientProfilePutRequestBody.EndDate ,
           "statusId":clientProfilePutRequestBody.statusId,
           "TrackMode": "mobileapp",
           "centerID": clientProfilePutRequestBody.centerID,
           "guid": clientProfilePutRequestBody.guid
  
        }
        return this.apigclient.appointmentPut(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
  
          let results;
  
          let params = {
            'Authorization': val
          }
          let body = {
            "xref_ScheduleId":clientProfilePutRequestBody.xref_ScheduleId,
            "StartDate": clientProfilePutRequestBody.StartDate,
            "EndDate": clientProfilePutRequestBody.EndDate ,
            "statusId":clientProfilePutRequestBody.statusId,
            "TrackMode": "mobileapp",
            "centerID": clientProfilePutRequestBody.centerID,
   
         }
          return this.apigclient.appointmentPut(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
  
        })
      }
    })
  
    return  from( Promise.resolve(clientProfilePut));
  
  }






















  // PurchaseHistoryPostInvoicePDF(pdfBody,tocken): Observable<any> {
  //   if(this.checkTokenValidity())
  //   {
  //     let results;
  //     let params;
  //     let trainerlist;

  //       params={
  //         'Authorization': tocken,
  //         'invoiceid':pdfBody.invoiceid,
  //         'emailto':''

  //       }

  //       trainerlist = this.apigclient.invoicesGenerateinvoicepdfPost(params)
  //       .then(response => {
  //         results = this.nullEliminate(response);
  //         return results;
  //       })
  //       .catch();


  //     return Promise.resolve(trainerlist);
  // }
  // else
  // {
  //   return Promise.resolve(Error('Token Expired')); 
  // }
  // }
  //**********MY SCHEDULE FLOW************//

  //MY SCHEDULE PAGE [SCHEDULE LISt]
  SchedulingGetSchedulelist(StartDatee, tocken, scheduleFromDateToDateCacheObj): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params;
        params = {
          'Authorization': tocken,
          'todate': StartDatee.todate,
          'fromdate': StartDatee.fromdate,
          'lang_isocode': scheduleFromDateToDateCacheObj.lang_isocode,
          'MemberID': scheduleFromDateToDateCacheObj.MemberID,
          'schedulecachebuster': scheduleFromDateToDateCacheObj.schedulecachebuster
        }
        return this.apigclient.membersIdSchedulesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params;
          params = {
            'Authorization': val,
            'todate': StartDatee.todate,
            'fromdate': StartDatee.fromdate,
            'lang_isocode': scheduleFromDateToDateCacheObj.lang_isocode,
            'MemberID': scheduleFromDateToDateCacheObj.MemberID,
            'schedulecachebuster': scheduleFromDateToDateCacheObj.schedulecachebuster
          }
          return this.apigclient.membersIdSchedulesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });

      }
    })
    return from(Promise.resolve(trainerlist));
  }

  //**********CLASSES FLOW************//

  //CLASSES PAGE [CLASSES LIST]
  ClassesGetClassList(date, tocken, classCache): Observable<any> {
    this.checkTokenValidity().then(value => { })
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'Date': date,
          'lang_isocode': classCache.lang_isocode,
          'MemberID': classCache.MemberID,
          'CenterID': classCache.CenterID,
          'schedulecachebuster': classCache.schedulecachebuster
        }
        return this.apigclient.classesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'Date': date,
            'lang_isocode': classCache.lang_isocode,
            'MemberID': classCache.MemberID,
            'CenterID': classCache.CenterID,
            'schedulecachebuster': classCache.schedulecachebuster

          }
          return this.apigclient.classesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })
    return from(Promise.resolve(trainerlist));
  }

  V2ClassesGetClassList(date, tocken, classCache): Observable<any> {
    this.checkTokenValidity().then(value => { })
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'Date': date,
          'isoCode': classCache.lang_isocode,
          'MemberID': classCache.MemberID,
          'centerId': classCache.CenterID,
          'companyId': this.companyDetails.CompanyId,
          'schedulecachebuster': classCache.schedulecachebuster
        }
        return this.apigclient.v2ClassesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'Date': date,
            'isoCode': classCache.lang_isocode,
            'MemberID': classCache.MemberID,
            'centerId': classCache.CenterID,
            'schedulecachebuster': classCache.schedulecachebuster

          }
          return this.apigclient.v2ClassesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })
    return from(Promise.resolve(trainerlist));
  }
  
  V3ClassesGetClassList(date, tocken, isocode, centerid, classCache): Observable<any> {
    this.checkTokenValidity().then(value => { })
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results; 
        let params = {
          'Authorization': tocken,
          'date': date,
          'isoCode': isocode,
          'MemberID': classCache.MemberID,
          'centerId': centerid,
          'companyId': this.companyDetails.CompanyId,
          'schedulecachebuster': classCache.schedulecachebuster
        }
        return this.apigclient.v4ClassesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'Date': date,
            'isoCode': classCache.lang_isocode,
            'MemberID': classCache.MemberID,
            'centerId': classCache.CenterID,
            'schedulecachebuster': classCache.schedulecachebuster

          }
          return this.apigclient.v4ClassesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })
    return from(Promise.resolve(trainerlist));
  }

  getUserProfileUpdate(token: string): Observable<any> {
    let clientProfile = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params: any;

        params = {
          'Authorization': token
        }
        return this.apigclient.membersIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results: any;
          let params: any;
          params = {
            'Authorization': val
          }
          return this.apigclient.membersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })
    return from(Promise.resolve(clientProfile));
  }

  GetOffers(token: string, paramsData: any): Observable<any> {
    let result = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params: any = {
          'Authorization': token,
          'lang_isocode': paramsData.lang_isocode,
          'MemberID': paramsData.MemberID,
          'CenterID': paramsData.CenterID,
          'BillMembershipCachebuster': paramsData.schedulecachebuster,
          'schedulecachebuster': paramsData.schedulecachebuster
        }
        return this.apigclient.offersGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
    })
    return from(Promise.resolve(result));
  }

  V2GetOffers(token: string, paramsData: any): Observable<any> {
    let result = this.checkTokenValidity().then(T => {
    if (T) {
    let results: any;
    let params: any = {
    'Authorization': token,
    'isoCode': paramsData.lang_isocode,
    'MemberID': paramsData.MemberID,
    'centerID': paramsData.CenterID,
    'BillMembershipCachebuster': paramsData.schedulecachebuster,
    'schedulecachebuster': paramsData.schedulecachebuster
    }
    return this.apigclient.v2OffersGet(params)
    .then(response => {
    results = this.nullEliminate(response);
    return results;
    })
    .catch();
    }
    else {
    return this.storage.get('tocken').then((val) => {
    let results: any;
    let params: any;
    params = {
    'Authorization': val
    }
    return this.apigclient.v2OffersGet(params)
    .then(response => {
    results = this.nullEliminate(response);
  
    return results;
    })
    .catch();
    })
    }
    })
    return from(Promise.resolve(result));
    }

  GoToJoinClass(classID: string, token: string): Observable<any> {
    let result = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params = { 'Authorization': token }
        let body =
        {
          "Trackmode": "mobileapp",
          'classid': classID
        }
        return this.apigclient.classesIdBookingPost(params, body).then(responce => {
          results = this.nullEliminate(responce);
          return results;
        }).catch();
      }
    });
    return from(Promise.resolve(result));
  }

  GetClassDetailsByClassID(classID: string, token: string,isocode): Observable<any> {
    let result = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;
        let params = {
          'Authorization': token,
          "ClassId": classID,
          "isoCode": isocode
        }
        return this.apigclient.classesIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
    });
    return from(Promise.resolve(result));
  }

  //CLASSES PAGE [PURPOSE SESSIONS]
  // ClassesGetPurposeSessions(): Observable<any> {
  //   return from(this.classes.getPurposeSessions(this.httpOptions));
  // }

  //CLASS DETAILS PAGE [CLASS DETAILS]
  ClassDetailsGetClassDetails(id, tocken, offerByOtpCache): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
          "ClassId": id
        }

        let results;
        return this.apigclient.classesIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
           
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let params = {
            'Authorization': val,
            "ClassId": id
          }

          let results;
          return this.apigclient.classesIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
             
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(trainerlist));

  }



  // WITHDRAW CLASSES
  GoToWithDrawClass(classDetails: any, tocken: string): Observable<any> {
    let withdraClass = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken
        }
        let body = {
          "Trackmode": "mobileapp",
          'classid': classDetails.ClassId,
          'reason': classDetails.reason
        }
        let results;
        return this.apigclient.classesIdWithdrawDelete(params, body)
          .then(response => {
            results = response
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val
          }
          let body = {
            "Trackmode": "mobileapp",
            'classid': classDetails.classid,
            'reason': classDetails.reason == undefined ? 'empty reason' : classDetails.reason
          }
          let results: any;
          return this.apigclient.classesIdWithdrawDelete(params, body)
            .then(response => {
              results = response
            
              return results;
            })
            .catch();
        });
      }
    })
    return from(Promise.resolve(withdraClass));
  }

  //CLASS RATING PAGE [RATING TAGS]
  classRatingGetRatingTags(tocken,iso): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
       
        let params = {
          'Authorization': tocken,
          'lang_code': iso

        }
        return this.apigclient.appointmentRatingreasonGet(params)
          .then(response => {
            return response;
          })
          .catch();
      }
      else {
       
        return this.storage.get('tocken').then((val) => {
          let tocken = val;
          let params = {
            'Authorization': tocken,
            'lang_code': iso

          }
          return this.apigclient.appointmentRatingreasonGet(params)
            .then(response => {
              return response;
            })
            .catch();
        })
      }

    })

    return from(Promise.resolve(trainerlist));
   // return Observable.fromPromise(this.fetcher.getRatingTags(this.httpOptions));
  }

  AppointmentAppointmentstatusGet(tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
       
        let params = {

          'Authorization': tocken,
        }
        return this.apigclient.appointmentAppointmentstatusGet(params)
          .then(response => {
            return response;
          })
          .catch();
      }
      else {
       
        return this.storage.get('tocken').then((val) => {
          let tocken = val;
          let params = {
            'Authorization': tocken,
          }
          return this.apigclient.appointmentAppointmentstatusGet(params)
            .then(response => {
              return response;
            })
            .catch();
        })
      }

    })

    return from(Promise.resolve(trainerlist));
   // return Observable.fromPromise(this.fetcher.getRatingTags(this.httpOptions));
  }


  //CLASS RATING PAGE [RATING TAGS]
  // classRatingGetRatingTags(): Observable<any> {
  //   return fromPromise(this.fetcher.getRatingTags(this.httpOptions));
  // }

  //FILTER
  // filterGetFilterTags(): Observable<any> {
  //   return Observable.fromPromise(this.classes.getFilterTags(this.httpOptions));
  // }

  //LOCATION MODAL [POPULAR LOCATIONS]
  // locationGetPopularLocations(): Observable<any> {
  //   return Observable.fromPromise(this.classes.getPopularLocations(this.httpOptions));
  // }

  //**********TRAINER FLOW************//





  //TRAINER LIST PAGE [TRAINER LIST]


  TrainerListGetDetail(tocken: string, paramObject: any): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results: any;

      
        let params = {
          'Authorization': tocken,
          'lang_IsoCode': paramObject.lang_isocode || paramObject.lang_IsoCode,
          'centerid': paramObject.CenterID
        }
        return this.apigclient.v2InstructorsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
       
        return this.storage.get('tocken').then((val) => {
          let tocken = val;
          let results;
          let params = {
            'Authorization': tocken,
            'lang_isocode': paramObject.lang_isocode || paramObject.lang_IsoCode,
            'CenterID': paramObject.CenterID,
            'BillMembershipCachebuster': paramObject.BillMembershipCachebuster,
            'schedulecachebuster': paramObject.schedulecachebuster
          }
          return this.apigclient.v2InstructorsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        })
      }
    })
    return from(Promise.resolve(trainerlist));
  }

  MarkAttendancePut(token,body): Observable<any> {

    let trainerlist = this.checkTokenValidity().then(T => {

      if (T) {
        let results;
        let params = {
          'Authorization': token
        }


        return this.apigclient.ENTERNAMEHERE(params, body)
          .then(response => {
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val

          }

          return this.apigclient.ENTERNAMEHERE(params, body)
            .then(response => {
              return results;
            })
            .catch();

        });
      }

    })

    return from(Promise.resolve(trainerlist));

  }



  CartProductValidity(body, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken

        }

        //change method name 0--
        return this.apigclient.billingValidatecartPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val

          }

          //change method name 0--
          return this.apigclient.billingValidatecartPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })


    return from(Promise.resolve(trainerlist));

  }



  BillingCallPost(body, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {

      if (T) {
        let results;
        let params = {
          'Authorization': tocken

        }

        return this.apigclient.billingPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val

          }

          return this.apigclient.billingPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }

    })

    return from(Promise.resolve(trainerlist));

  }



  CreateGVPost(body, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {

      if (T) {
        let results;
        let params = {
          'Authorization': tocken

        }

        return this.apigclient.billingCreategvPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val

          }

          return this.apigclient.billingCreategvPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }

    })

    return from(Promise.resolve(trainerlist));

  }



  OverlappingPost(body, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken

        }

        return this.apigclient.membersIdCheckoverlapPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val

          }

          return this.apigclient.membersIdCheckoverlapPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerlist));

  }



  // TrainerListGetDetail(): Observable<any> {
  //   return Observable.fromPromise(this.trainerlist.getTrainerlist(this.httpOptions));
  // }

  TrainerDetailGetDetail(trainerListRequestBody, tocken, instructorsIdCacheObj): Observable<any> {
    let trainerdetail = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'ID': trainerListRequestBody,
          'lang_isocode': instructorsIdCacheObj.lang_isocode,
          'BillMembershipCachebuster': instructorsIdCacheObj.BillMembershipCachebuster,
          'schedulecachebuster': instructorsIdCacheObj.schedulecachebuster

        }
        return this.apigclient.instructorsIdGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'ID': trainerListRequestBody.ID,
            'lang_isocode': instructorsIdCacheObj.lang_isocode,
            'BillMembershipCachebuster': instructorsIdCacheObj.BillMembershipCachebuster,
            'schedulecachebuster': instructorsIdCacheObj.schedulecachebuster
          }
          return this.apigclient.instructorsIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerdetail));

  }

     //GV INFO
     BillingGVInfo(centerID,tocken): Observable<any> {
      let billingInfo = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'centerID':centerID
          }
          return this.apigclient.billingGetgvinfoGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'Authorization': val,
              'centerID':centerID
            }
            return this.apigclient.billingGetgvinfoGet(params)
              .then(response => {
                results = this.nullEliminate(response);
             //   console.log(results, 'inter-club')
                return results;
              })
              .catch();
          });
        }
      })
  
      return from(Promise.resolve(billingInfo));
  
    }

//GiftAMembership-Videos
    BillingEnabledFeatures(centerID,token,isocode)
    {
      let billingInfo = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': token,
            'centerId':centerID,
            'isoCode':isocode
          }
          return this.apigclient.billingEnabledfeaturesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'Authorization': token,
              'centerId':centerID,
              'isoCode':isocode
            }
            return this.apigclient.billingEnabledfeaturesGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          });
        }
      })
  
      return from(Promise.resolve(billingInfo));
  
    }

    v2BillingEnabledFeatures(centerID,token,isocode)
    {

      let billingInfo = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': token,
            'centerId':centerID,
            'isoCode':isocode
          }
          return this.apigclient.v2BillingEnabledfeaturesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'Authorization': token,
              'centerId':centerID,
              'isoCode':isocode
            }
            return this.apigclient.v2BillingEnabledfeaturesGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          });
        }
      })
  
      return from(Promise.resolve(billingInfo));
  
    }


  // TrainerDetailGetDetail(id): Observable<any> {
  //   return Observable.fromPromise(this.trainerlist.getTrainerDetail(this.httpOptions));
  // }



  //**********TRAINER VIDEOS FLOW************//

  //**********FEEDBACK FLOW************//




  //return Observable.fromPromise(this.feedback.postFeedback(feedback, this.httpOptions));
  // }

  //**********APPOINTMENT FLOW************//

  //DATETIME PAGE [SLOTS DETAILS]
  // dateTimeGetSlotsDetails(): Observable<any> {
  //   return Observable.fromPromise(this.validPhone.getSlots(this.httpOptions));
  // }

  //REPEAT BOOKING PAGE [REPEAT BOOKING DETAILS]
  // appointmentBookedGetRepeatBookingDetails(): Observable<any> {
  //   return Observable.fromPromise(this.repeatBooking.repeatBookingDetails(this.httpOptions));
  // }


  // classjoininterm
  classjoininterm(id, token): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': token,
        }

        let body =
        {
          "Trackmode": "mobileapp",
          'classid': id
        }

        let results;
        return this.apigclient.classesIdBookingPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
          }

          let body =
          {
            "Trackmode": "mobileapp",
            'classid': id
          }

          let results;
          return this.apigclient.classesIdBookingPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerlist));

  }





  //TRANSACTION
  transactionHistoryGetTransactionHistoryList(tocken, invoiceCache): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'lang_isocode': invoiceCache.lang_isocode,
          'MemberID': invoiceCache.MemberID,
          'BillMembershipCachebuster': invoiceCache.BillMembershipCachebuster
        }
        return this.apigclient.membersIdInvoicesGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'lang_isocode': invoiceCache.lang_isocode,
            'MemberID': invoiceCache.MemberID,
            'BillMembershipCachebuster': invoiceCache.BillMembershipCachebuster
          }
          return this.apigclient.membersIdInvoicesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(trainerlist));

  }







  // REF CODE API 

  CartRefcodeValidation(refObjec,CenterId, tocken): Observable<any> {

    let refralcode = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'referralcode': refObjec,
          'CenterId':CenterId
        }

        return this.apigclient.userReferralcodeGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();

      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': tocken,
            'referralcode': refObjec
          }
          return this.apigclient.userReferralcodeGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })


    return from(Promise.resolve(refralcode));
  }










  //Cart-Waiver-Action Modal 
  CartWaiverActionModalGetWaiverTerms(tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken
        }
        return this.apigclient.waiverStatusGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val
          }
          return this.apigclient.waiverStatusGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })

    return from(Promise.resolve(trainerlist));



  }






  // Cart-Waiver-OTP  
  CartWaiverActionModalGetWaiverOtp(tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken
        }
        return this.apigclient.waiverStatusGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val
          }
          return this.apigclient.waiverStatusGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();


        });
      }
    })

    return from(Promise.resolve(trainerlist));


  }




  // Cart-waiver-esign
  CartWaiverEsignGetWaiverEsign(tocken, signature, type): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken
        }
        let body = {
          "requesttype": type,
          "payload": signature
        }

        return this.apigclient.waiverUploadwaiverformsPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val
          }
          let body = {
            "requesttype": type,
            "payload": signature
          }

          return this.apigclient.waiverUploadwaiverformsPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(trainerlist));


  }

  //TRANSACTION
  // dashboardGymList(): Observable<any> {
  //   return Observable.fromPromise(this.dashboard.getDashboardGym(this.httpOptions));
  // }







  // //////-------------------------------------------- NOT NEEDED -----------------------------------------------------------------//////



  // //VIDEO LIST
  // VideoListGetVideoDetails() {
  //   return Observable.fromPromise(this.vid.getVideoDetails());
  // }

  // renewGetCity(): Observable<any> {
  //   return Observable.fromPromise(this.fetcher.getCities());
  // }

  // //VIDEO CATEGORY
  // VideoCategoryGetVideoCategory(): Observable<any> {
  //   return Observable.fromPromise(this.vid.getVideoCategory());
  // }

  // //Wallet PAge
  // walletGetWalletDetails(): Observable<any> {
  //   return Observable.fromPromise(this.wallet.getWalletDetails());
  // }

  // //MY MEMBERSHIPS
  // myMembershipGetMyMembershipDetails(): Observable<any> {
  //   return Observable.fromPromise(this.myMembership.getMyMembershipDetails());
  // }
  // //REFERRAL
  // referralGetReferralDetails(): Observable<any> {
  //   return Observable.fromPromise(this.referral.getReferralDetails());
  // }

  // //DASHBOARD
  // DashboardGetFacilityDetails(): Observable<any> {
  //   return Observable.fromPromise(this.dashboard.getSpecificFacilityDetails(this.httpOptions));
  // }




  // //SPECIF TRAINER
  // specificTrainerGetTrainerDetails(): Observable<any> {
  //   return Observable.fromPromise(this.specificTrainer.getSpecificTrainerDetails());
  // }

  // //Appointment Attendance Popup Page
  // AppointmentAttendanceGetClassAttendance(): Observable<any> {
  //   return Observable.fromPromise(this.classes.getClassAttendance());
  // }

  // //APPOINTMENT BOOOKED
  // appointmentBookedGetBookedOrder(): Observable<any> {
  //   return Observable.fromPromise(this.fetcher.getBookedOrder());
  // }

  // //Dashboard
  // DashboardGetPurposeList(): Observable<any> {
  //   return Observable.fromPromise(this.dashboard.getPurposeList(this.httpOptions));
  // }


  // /// Withouttt Token
  // withoutTokeninvokeMemberId(clientProfileRequestBody): Observable<any> {
  //   if (this.checkTokenValidity()) {
  //     //return Observable.fromPromise(this.dashboard.getClientProfile(clientProfileRequestBody, this.httpOptions));
  //     let results;
  //     let params = {

  //       'mobileno': '999' + clientProfileRequestBody.mobileno,
  //       'companyid': clientProfileRequestBody.companyid
  //     }
  //     let clientProfile = this.apigclient.otpMemberdetailsbymobilenoGet(params)
  //       .then(response => {
  //         results = this.nullEliminate(response);
  //         console.log(results, 'huh')
  //         return results;
  //       })
  //       .catch();
  //     return from(Promise.resolve(clientProfile));

  //   }
  //   else {
  //     return from(Promise.resolve(Error('Token Expired')));
  //   }

  // }





  /// Withouttt Token
  withoutTokeninterCompanyID(clientProfileRequestBody): Observable<any> {
    if (this.checkTokenValidity()) {
      //return Observable.fromPromise(this.dashboard.getClientProfile(clientProfileRequestBody, this.httpOptions));
      let results;
      let params = {

        "companyid": this.companyDetails.CompanyId
      }
      let clientProfile = this.apigclient.otpCompaniesIdGet(params)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
      return from(Promise.resolve(clientProfile));

    }
    else {
      return from(Promise.resolve(Error('Token Expired')));
    }

  }




  CartValidateGv(tocken, reqbody): Observable<any> {

    let brandlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'lang_IsoCode': reqbody.lang_IsoCode,
          'gvid': reqbody.gvid,
          'CenterId': reqbody.CenterId,
          'Authorization': tocken
        }


        return this.apigclient.billingValidategvGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch(e => {
            return e
          });
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'lang_IsoCode': reqbody.lang_IsoCode,
            'gvid': reqbody.gvid,
            'CenterId': reqbody.CenterId,
            'Authorization': tocken
          }

          return this.apigclient.billingValidategvGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });

        })
      }
    })

    return from(Promise.resolve(brandlist));
  }


  CartValidateCoupon(tocken, cCode,centerID): Observable<any> {

    let brandlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'couponCode': cCode,
          'Authorization': tocken,
          'centerID':centerID
        }


        return this.apigclient.billingGetcouponinfoGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch(e => {
            return e
          });
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'couponCode': cCode,
            'Authorization': tocken,
            'centerID':centerID
          }

          return this.apigclient.billingGetcouponinfoGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });

        })
      }
    })

    return from(Promise.resolve(brandlist));
  }


  withoutTokeninterCenterList(companyID, IsoCode: string, getAllCenterCache: any): Observable<any> {
    if (this.checkTokenValidity()) {

      let results;
      let params = {
        'lang_isocode': IsoCode,
        'companyID': this.companyDetails.CompanyId
      }
      let centerList = this.apigclient.otpCentersGet(params)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
      return from(Promise.resolve(centerList));
    }
    else {
      return from(Promise.resolve(Error('Token Expired')));
    }
  }



  withoutTokeninterCenterID(clientProfileRequestBody, centerByIdCache): Observable<any> {
    if (this.checkTokenValidity()) {
      //return Observable.fromPromise(this.dashboard.getClientProfile(clientProfileRequestBody, this.httpOptions));
      let results;
      let params = {

        'centerid': clientProfileRequestBody.TenantId,
        'lang_isocode': clientProfileRequestBody.lang_isocode,
        'companyid': this.companyDetails.CompanyId


      }

      let clientProfile = this.apigclient.otpCentersIdGet(params)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
      return from(Promise.resolve(clientProfile));

    }
    else {
      return from(Promise.resolve(Error('Token Expired')));
    }
  }







  invokeAppComponentPostDeviceDetails(detailsObj, tocken): Observable<any> {
    //  this.presentLoading();
    if (this.checkTokenValidity()) {
      let params = {
        'Authorization': tocken,
      }
      let body = {
        'deviceUuid': detailsObj.deviceUuid,
        'deviceVersion': detailsObj.deviceVersion,
        'deviceCordova': detailsObj.deviceCordova,
        'deviceIsVirtual': detailsObj.deviceIsVirtual,
        'deviceManufacturer': detailsObj.deviceManufacturer,
        'deviceModel': detailsObj.deviceModel,
        'devicePlatform': detailsObj.devicePlatform,
        'deviceSerial': detailsObj.deviceSerial,
        'appversion': detailsObj.appversion
      }
      let results;
      let Device = this.apigclient.membersIdDeviceinfoPost(params, body)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();

      return from(Promise.resolve(Device));
    }
  }






  withoutTokeninterSendOTP(clientProfileRequestBody): Observable<any> {
    if (this.checkTokenValidity()) {
      //return Observable.fromPromise(this.dashboard.getClientProfile(clientProfileRequestBody, this.httpOptions));
      let results;
      let params = {


      }
      let clientProfile = this.apigclient.otpSendPost(params, clientProfileRequestBody)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
      return from(Promise.resolve(clientProfile));

    }
    else {
      return from(Promise.resolve(Error('Token Expired')));
    }
  }


  OTPVerifyPost(otpID, otpcode, tocken): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken
        }


        let bodyCopy = {
          "requesttype": "otp",
          "otpID": otpID,
          "otpCode": otpcode
        }

        let body = {
          'trackMode': 'mobileapp',
          "requesttype": "otp",
          "payload": JSON.stringify(bodyCopy)
        }


        let results;
        return this.apigclient.waiverUploadwaiverformsPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let params = {
            'Authorization': val
          }


          let bodyCopy = {
            "requesttype": "otp",
            "otpID": otpID,
            "otpCode": otpcode
          }

          let body = {
            'trackMode': 'mobileapp',
            "requesttype": "otp",
            "payload": JSON.stringify(bodyCopy)
          }


          let results;
          return this.apigclient.waiverUploadwaiverformsPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })



    return from(Promise.resolve(trainerlist));


  }

  RatingPost(token,rating,feedback,memID,xref,isocode): Observable<any> {
    let body =
    {
      "xrefScheID":xref,
      "rating":rating,
      "totalRating":5,
      "reason":feedback
      } 
    
    let trainerlist = this.checkTokenValidity().then(T => {

      if (T) {
        let results;
        let params = {
          'Authorization': token,
          'isoCode':isocode
        }


        return this.apigclient.membersRatingPost(params, body)
          .then(response => {
            return response;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': token,
            'isoCode':isocode
          }

          return this.apigclient.membersRatingPost(params, body)
            .then(response => {
              return response;
            })
            .catch();

        });
      }

    })

    return from(Promise.resolve(trainerlist));

  }



  SyncCartPost(cart, tocken): Observable<any> {
    if (this.checkTokenValidity()) {
      let params = {
        'Authorization': tocken
      }

      let bodyCopy = {
        "cart": cart,
      }

      let results = true;

      return from(Promise.resolve(results));

    }

  }


  CartValidityGet(cart, tocken) {
    if (this.checkTokenValidity()) {
      let params = {
        'Authorization': tocken,
        "cart": cart,
      }


      let results = true;

      return Promise.resolve(results);


    }
  }


  AppComponentPostDeviceDetails(detailsObj, tocken) {
    let deviceDetails = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
        }
        let body = {
          'deviceUuid': detailsObj.deviceUuid,
          'deviceVersion': detailsObj.deviceVersion,
          'deviceCordova': detailsObj.deviceCordova,
          'deviceIsVirtual': detailsObj.deviceIsVirtual,
          'deviceManufacturer': detailsObj.deviceManufacturer,
          'deviceModel': detailsObj.deviceModel,
          'devicePlatform': detailsObj.devicePlatform,
          'deviceSerial': detailsObj.deviceSerial,
          'appversion': detailsObj.appversion
        }
        let results;
        return this.apigclient.membersIdDeviceinfoPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
          }
          let body = {
            'deviceUuid': detailsObj.deviceUuid,
            'deviceVersion': detailsObj.deviceVersion,
            'deviceCordova': detailsObj.deviceCordova,
            'deviceIsVirtual': detailsObj.deviceIsVirtual,
            'deviceManufacturer': detailsObj.deviceManufacturer,
            'deviceModel': detailsObj.deviceModel,
            'devicePlatform': detailsObj.devicePlatform,
            'deviceSerial': detailsObj.deviceSerial,
          }
          let results;
          return this.apigclient.membersIdDeviceinfoPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });



      }
    })




    return Promise.resolve(deviceDetails);
  }

  GetBrands(companyID: Number, isoCode: string): Observable<any> {
    let results: any;
        let params: any;
        params = {
          'isoCode': isoCode,
          'companyid': companyID
        }

    let brands = this.apigclient.otpBrandsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      
  
    return from(Promise.resolve(brands));
  }

  SelectBrandGetDetails(obj, tocken) {
    let brandlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'isoCode': obj,

        }

        return this.apigclient.brandsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'isoCode': obj.lang_isocode
          }

          return this.apigclient.brandsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })



    return Promise.resolve(brandlist);

  }

  CartGetGUID(obj, tocken): Observable<any> {

    let brandlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'isoCode': obj.lang_isocode,
        }


        return this.apigclient.v2BillingValidatecartPost(params, obj)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val,
            'isoCode': obj.lang_isocode
          }

          return this.apigclient.v2BillingValidatecartPost(params, obj)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })



    return from(Promise.resolve(brandlist));
  }

  Freeze(obj:any, tocken:string, isoCode:string):Observable<any> {
    let body = {
      "isspecial_freeze_enabled":obj.isspecial_freeze_enabled,
      "membershipID": obj.membershipID,
      "centerID": obj.centerID,
      "comments": obj.comments,
      "fromDate": obj.fromDate,
      "toDate": obj.toDate,
      "additionalPaymentID": obj.additionalPaymentID,
      "payment": obj.payment,
      'isoCode': isoCode,
      'trackMode': 'mobileapp'
    }
    let frezeResponse = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
          'isoCode': isoCode
        }
        let results;
        return this.apigclient.billingFreezePost(params, body)
          .then(response => {
            results = response;
            return results;
          })
          .catch(e => {
            return e
          });
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
            'isoCode': isoCode
          }
          let results;
          return this.apigclient.billingFreezePost(params, body)
            .then(response => {
              results = response;
              return results;
            })
            .catch(e => {
              return e
            });
        })
      }
    })
    return from(Promise.resolve(frezeResponse));
  }



  //--------------CLIENT PROFILE PUT REQUEST--------------------------//
  EditProfilePutDetails(clientProfilePutRequestBody, tocken): Observable<any> {
    let clientProfilePut = this.checkTokenValidity().then(T => {
      if (T) {
        let results;

        let params = {
          'Authorization': tocken
        }
        let body = {
          'fname': clientProfilePutRequestBody.fname,
          'lname': clientProfilePutRequestBody.lname,
          'contact': clientProfilePutRequestBody.number,
          'dob': clientProfilePutRequestBody.dob,
          'emailAddress': clientProfilePutRequestBody.email
        }
        // return this.apigclient.membersIdPut(params, body)
        //   .then(response => {
        //     results = this.nullEliminate(response);
        //     console.log(results, 'huh')
        //     return results;
        //   })
        //   .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;

          let params = {
            'Authorization': val
          }
          let body = {
            'fname': clientProfilePutRequestBody.fname,
            'lname': clientProfilePutRequestBody.lname,
            'contact': clientProfilePutRequestBody.number,
            'dob': clientProfilePutRequestBody.dob,
            'emailAddress': clientProfilePutRequestBody.email
          }
          // return this.apigclient.membersIdPut(params, body)
          //   .then(response => {
          //     results = this.nullEliminate(response);
          //     console.log(results, 'huh')
          //     return results;
          //   })
          //   .catch();

        })
      }
    })
    return from(Promise.resolve(clientProfilePut));
  }

  //REPEAT BOOKING PAGE [REPEAT BOOKING DETAILS]
  appointmentSuccessfulBooked(token, body): Observable<any> {
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': token
        }

        return this.apigclient.appointmentPost(params, body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {

          let results;
          let params = {
            'Authorization': val
          }


          return this.apigclient.appointmentPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        });
      }
    })

    return from(Promise.resolve(trainerlist));
  }


  //Get payment success json start
 BillingPaymentJson(transactionId, isoCode, tocken): Observable<any> {
    let paymentInfo = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'transactionId':transactionId,
          'isoCode':isoCode
        }
        return this.apigclient.billingPaymentjsonGet(params)
          .then(response => {
            results = this.nullEliminate(response);
          //  console.log(results, 'inter-club')
            return results;
          })
          .catch( response => {
            return response;
          })
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': val,
            'transactionId':transactionId,
            'isoCode':isoCode
          }
          return this.apigclient.billingPaymentjsonGet(params)
            .then(response => {
              results = this.nullEliminate(response);
           //   console.log(results, 'inter-club')
              return results;
            })
            .catch( response => {
              return response;
            })
        });
      }
    })

    return from(Promise.resolve(paymentInfo));

  }

  //Get payment success json end



  Fcmtokenget(Body, tocken): Promise<any> {
    let otpVerified = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': tocken,
        }

        //return Observable.fromPromise(this.login.getOtpVerified(otpVerificationRequestBody, this.httpOptions));
        let results;
        return this.apigclient.membersIdGcmtokenPost(params,Body)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
          }

          //return Observable.fromPromise(this.login.getOtpVerified(otpVerificationRequestBody, this.httpOptions));
          let results;
          return this.apigclient.membersIdGcmtokenPost(params,Body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();

        })
      }
    })

    return Promise.resolve(otpVerified);


  }


  GcmTokenDelete(token,deviceID)
  {
    let withdraClass = this.checkTokenValidity().then(T => {
      if (T) {
        let params = {
          'Authorization': token,
          'deviceID':deviceID
        }
        let body = {}
        let results;
        return this.apigclient.membersIdGcmtokendeleteDelete(params, body)
          .then(response => {
            results = response
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let params = {
            'Authorization': val,
            'deviceID':deviceID
          }
          let body = {}
          let results: any;
          return this.apigclient.membersIdGcmtokendeleteDelete(params, body)
            .then(response => {
              results = response
            
              return results;
            })
            .catch();
        });
      }
    })
    return from(Promise.resolve(withdraClass));
  }

  //Member Checkins
  MemberCheckIns(month,isocode, tocken): Observable<any> {
    this.checkTokenValidity().then(value => { })
    let trainerlist = this.checkTokenValidity().then(T => {
      if (T) {
        let results;
        let params = {
          'Authorization': tocken,
          'month': month,
          'isoCode':isocode
        }
        return this.apigclient.v2MembersIdCheckinsGet(params)
          .then(response => {
            results = this.nullEliminate(response);
            return results;
          })
          .catch();
      }
      else {
        return this.storage.get('tocken').then((val) => {
          let results;
          let params = {
            'Authorization': tocken,
            'month': month
            }
          return this.apigclient.v2MembersIdCheckinsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        });
      }
    })
    return from(Promise.resolve(trainerlist));
  }

    //OTP PAGE [OTP RESPONSE]
    BillingActivateDayPass(odpResponse, isoCode, tocken): Observable<any> {

      let body = {
         "QRCode": odpResponse.QRCode,
        "comments": odpResponse.comments,
        "deviceId": odpResponse.deviceId,
        "isoCode": isoCode,
     }
  
      let ODPResponse = this.checkTokenValidity().then(T => {
        if (T) {
          let params = {
            'Authorization': tocken
  
          }
  
          let results;
          return this.apigclient.billingActivatedaypassPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let params = {
              'Authorization': val
  
            }
  
            let results;
            return this.apigclient.billingActivatedaypassPost(params, body)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          })
        }
      })
  
      return from(Promise.resolve(ODPResponse));
  
  
    }

    RedeemMembershipGetProducts(productBody, tocken): Observable<any> {
      let trainerlist = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'gvCode': productBody.gvid,
            'isoCode':productBody.lang_IsoCode,
            'centerId':productBody.CenterId,
          }
          return this.apigclient.giftmembershipGvcodeProductsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(async(error) =>{
              return  error 
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'Authorization': tocken,
            'gvCode': productBody.gvid,
            'isoCode':productBody.lang_IsoCode,
            'centerId':productBody.CenterId,           
             }
            return this.apigclient.giftmembershipGvcodeProductsGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(async(error) =>{
                return  error 
              });
          });
        }
      })
  
      return from(Promise.resolve(trainerlist));
  
  
    }
    GiftMembershipCreatePost(token, body): Observable<any> {
      let trainerlist = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': token
          }
  
          return this.apigclient.giftmembershipCreategvPost(params, body)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
              'Authorization': val
            }
  
  
            return this.apigclient.giftmembershipCreategvPost(params, body)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
  
          });
        }
      })
  
      return from(Promise.resolve(trainerlist));
    }

    GiftmembershipProductsGet (productBody, tocken): Observable<any> {
      let trainerlist = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'companyId': this.companyDetails.CompanyId,
            'isoCode':productBody.isoCode,
            'centerId' :productBody.centerid   
          }
          return this.apigclient.giftmembershipProductsGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
             'Authorization': val,
              'companyId': this.companyDetails.CompanyId,
              'isoCode':productBody.isoCode,
              'centerId' :productBody.centerid           
             }
            return this.apigclient.giftmembershipProductsGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          });
        }
      })
  
      return from(Promise.resolve(trainerlist));
  
  
    }  
    ValidateGiftMembershipGet(tocken, productBody): Observable<any> {

      let giftMembership = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'lang_IsoCode':productBody.lang_IsoCode,
            'isGiftMembership':productBody.isGiftMembership,
            'gvid': productBody.gvid,
            'CenterId':productBody.CenterId,
          }
  
  
          return this.apigclient.billingValidategiftmembershipGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
            'Authorization': tocken,
            'lang_IsoCode':productBody.isoCode,
            'isGiftMembership':productBody.isGiftMembership,
            'gvid': productBody.gvCode,
            'CenterId':productBody.centerid,
            }
  
            return this.apigclient.billingValidategiftmembershipGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(e => {
                return e
              });
  
          })
        }
      })
  
      return from(Promise.resolve(giftMembership));
    }

    ProductsEligibleCenters(tocken, productBody): Observable<any> {

      let giftMembership = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'isocode':productBody.lang_IsoCode,
            'centerIds': productBody.centerIds,
            'companyId':productBody.companyId,
          }
  
  
          return this.apigclient.productsEligiblecentersGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
              'Authorization': tocken,
              'isocode':productBody.lang_IsoCode,
              'centerIds': productBody.centerIds,
              'companyId':productBody.companyId,
            }
  
            return this.apigclient.productsEligiblecentersGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(e => {
                return e
              });
  
          })
        }
      })
  
      return from(Promise.resolve(giftMembership));
    }

    CartRefcodeValidationV2(refObjec,CenterId,isocode, tocken,company): Observable<any> {

      let refralcode = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'referralcode': refObjec,
            'centerId':CenterId,
            'companyId': company,
            'isoCode': isocode
          }
  
          return this.apigclient.v2UserReferralcodeGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
  
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
              'Authorization': tocken,
              'referralcode': refObjec,
              'centerId':CenterId,
              'companyId': company,
              'isoCode': isocode
            }
            return this.apigclient.v2UserReferralcodeGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
  
          })
        }
      })
  
  
      return from(Promise.resolve(refralcode));
    }




    offersOffersdetailsGuidGet(isoCode,centerid,guid,tocken): Observable<any> {
//isoCode', 'centerid', 'guid', 'Authorization'
      let giftMembership = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'isoCode':isoCode,
            'centerid':centerid,
            'guid':0,
            'Authorization':tocken
          }
  
  
          return this.apigclient.offersOffersdetailsGuidGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
              'isoCode':isoCode,
              'centerid':centerid,
              'guid':0,
              'Authorization':tocken
            }
  
            return this.apigclient.offersOffersdetailsGuidGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(e => {
                return e
              });
  
          })
        }
      })
  
      return from(Promise.resolve(giftMembership));
    }



    

    GiftmembershipGvcodeCentersGet(tocken, productBody): Observable<any> {

      let giftMembership = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'isoCode':productBody.lang_IsoCode,
            'companyId':productBody.companyId,
            'gvCode':productBody.gvCode
          }
  
  
          return this.apigclient.giftmembershipGvcodeCentersGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
              'Authorization': tocken,
              'isoCode':productBody.lang_IsoCode,
              'companyId':productBody.companyId,
              'gvCode':productBody.gvCode
            }
  
            return this.apigclient.giftmembershipGvcodeCentersGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(e => {
                return e
              });
  
          })
        }
      })
  
      return from(Promise.resolve(giftMembership));
    }

    StocksPost(tocken, query,stockbody): Observable<any> {

      console.log(tocken,query,stockbody,'got daaaattt in servericeee')
      let stocksGet = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'isoCode':query.langIsoCode,
            'companyId':query.companyId,
          }
  
          return this.apigclient.stocksAllPost(params,stockbody)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(e => {
              return e
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
  
            let results;
            let params = {
              'Authorization': tocken,
              'isoCode':query.langIsoCode,
              'companyId':query.companyId,
            }

            return this.apigclient.stocksAllPost(params,stockbody)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(e => {
                return e
              });
  
          })
        }
      })
  
      return from(Promise.resolve(stocksGet));
    }

    withTokeninterCenterID(tocken): Observable<any> {
      let centerDetails = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
          }
          return this.apigclient.centersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'Authorization': val,
            }
            return this.apigclient.centersIdGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          })
        }
      })
  
      return from(Promise.resolve(centerDetails));
  
    }

    withV3tokenCenterid(tocken,request): Observable<any> {
      let centerDetails = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'Authorization': tocken,
            'isoCode':request.isoCode,
            'centerId':request.centerId,
            'companyId': request.companyId
          }
          return this.apigclient.v3CentersIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch(b=>{
              console.log(b,'got errro')
              results = b;
              return results
            });
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'Authorization': tocken,
              'isoCode':request.isoCode,
              'centerId':request.centerId,
              'companyId': request.companyId
            }
            return this.apigclient.v3CentersIdGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch(b=>{
                console.log(b,'got errro')
                results = b;
                return results
              });
          })
        }
      })
  
      return from(Promise.resolve(centerDetails));
  
    }

    CheckEnable(RequestBody): Observable<any> {
      let otpResponse   = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
             "centerId": RequestBody.centerid,
             "companyId": RequestBody.companyid,
          }
          return this.apigclient.otpPaymentfeaturesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          // return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              "centerId": RequestBody.centerid,
              "companyId": RequestBody.companyid,
           }
            return this.apigclient.otpPaymentfeaturesGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          // })
        }
      })
  
      return from(Promise.resolve(otpResponse));
  
    }

    CentersWorkoutcategoriesGet(tocken, isocode, centerId): Observable<any> {
      let categoryList = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'isoCode': isocode,
            'centerId': centerId,
            'companyId': this.companyDetails.CompanyId,
            'Authorization': tocken
          }
          return this.apigclient.centersWorkoutcategoriesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'isoCode': isocode,
              'centerId': centerId,
              'companyId': this.companyDetails.CompanyId,
              'Authorization': tocken
            }
            return this.apigclient.centersWorkoutcategoriesGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          });
        }
      })
      return from(Promise.resolve(categoryList));
    }

    getVideosByPlaylistId(tocken, playlistId): Observable<any> {
      let videoList = this.checkTokenValidity().then(T => {
        if (T) {
          let results;
          let params = {
            'playListId': playlistId,
            'Authorization': tocken
          }
          return this.apigclient.playlistIdGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        }
        else {
          return this.storage.get('tocken').then((val) => {
            let results;
            let params = {
              'playListId': playlistId,
              'Authorization': tocken
            }
            return this.apigclient.playlistIdGet(params)
              .then(response => {
                results = this.nullEliminate(response);
                return results;
              })
              .catch();
          });
        }
      })
      return from(Promise.resolve(videoList));
    }

    otpV2BillingEnabledfeaturesGet(centerId): Observable<any> {
      let results: any;
          let params: any;
          params = {
            'centerId': centerId,
            'companyId': this.companyDetails.CompanyId
          }
  
      let brands = this.apigclient.otpV2BillingEnabledfeaturesGet(params)
            .then(response => {
              results = this.nullEliminate(response);
              return results;
            })
            .catch();
        
    
      return from(Promise.resolve(brands));
    }

    GetCompanyDetails(RequestBody): Observable<any> {
      let results: any;
      let params: any;
      params = {
        'subdomain': RequestBody.subdomain
      }

  let brands = this.apigclient.companiesSubdomainGet(params)
        .then(response => {
          results = this.nullEliminate(response);
          return results;
        })
        .catch();
  return from(Promise.resolve(brands));
  
    }

}