import { GlobalService } from './../global.service';
import { stringify } from 'querystring';
// import { FCM } from '@ionic-native/fcm/ngx';
import { Component, OnDestroy, OnInit, NgZone } from '@angular/core';
import { Platform, Events, ToastController, AlertController, ModalController, MenuController, ActionSheetController, PopoverController, Config } from '@ionic/angular';
import { Location } from '@angular/common';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { HttpService } from './services/http/http.service';
import { ThemeService } from './services/theme/theme.service';
import { NetworkErrorService } from './services/network-error/network-error.service';
import { debounceTime, timestamp } from 'rxjs/operators';
import { LogoutService } from './logout.service'
import { ViewChildren, QueryList } from '@angular/core';
import { IonRouterOutlet } from '@ionic/angular';
import { ChangeLanguagePage } from './pages/change-language/change-language.page';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { DataServiceService } from './services/data-service/data-service.service';
import { Device } from '@ionic-native/device/ngx';
// import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { LanguageService } from './services/language/language.service';
import * as moment from 'moment';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { DashboardUpdateService } from './services/dashboard-update/dashboard-update.service';
import { NetworkErrorPage } from './pages/network-error/network-error.page'
import { Network } from '@ionic-native/network/ngx';
//import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { GifSplashPage } from "./gif-splash/gif-splash.page"
//import { OnedayPass, Giftamembership, Reedeemamembership, Apptype, TealiumConfig, Pages, CompanyId } from './config';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { GateOpenClosePage } from './pages/gate-open-close/gate-open-close.page';
import { TealiumUtagService } from '../app/services/Tealium/TealiumService';
import { NotificationViewModalPage } from './pages/notification-view-modal/notification-view-modal.page';

import { UpdateAppService } from './update-app.service';
import { Subscription } from 'rxjs';
import { Pages, TealiumConfig } from './config';





@Component({
  selector: 'app-root',
  providers: [TealiumUtagService],
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnDestroy {

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  public clubArray: any = [];
  web: boolean = false;

  route = "dashboard";
  public extraMemberArray: any;
  public memberDetailsArray: Object;
  public activeCenter: any;
  public memberName: any;
  public activeStatus: any;
  public profileURL: any;
  public logo: any
  public lastTimeBackPress = 0;
  public timePeriodToExit = 2000;
  public preLoginPagesArray = [];
  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  public navigateUrl: string = undefined;
  public oneDayPass = 0;
  public oneDayPasses: number[] = [];
  public ISOCODE;
  public memberships: number[] = [];
  public tocken;
  public gender;
  public tenantID;
  public MemberID;
  pages = Pages;
  themeName: any;
  profileDetails: any;
  picUrl: any;
  memberStatus: any;
  firstName: any;
  LastName: any;
  ToastMessage: any;
  center: any;
  netModal
  isConnected: boolean = true;
  selectedPage: any;
  centerStatus: any;
  timestamp: string;
  Apptype: string;
  updateAlertSubscription: Subscription;
  OnedayPassShow: boolean;
  GiftamembershipShow: boolean;
  Reedeemamembership: boolean;

  constructor(
    public modalCtrl: ModalController,
    private menu: MenuController,
    private actionSheetCtrl: ActionSheetController,
    public platform: Platform,
    public location: Location,
    public fcm2: FirebaseX,
    private popoverCtrl: PopoverController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public toastCtrl: ToastController,
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient,
    private HTPS: HttpService,
    private storage: Storage,
    public events: Events,
    private updateApp: UpdateAppService,
    public networkService: NetworkErrorService,
    public alertController: AlertController,
    public theme: ThemeService,
    public toast: ToastController,
    private logout: LogoutService,
    public languageService: LanguageService,
    // private fcm: FCM,
    public zone: NgZone,
    private screenOrientation: ScreenOrientation,
    private dataService: DataServiceService,
    private devicee: Device,
    private keyboard: Keyboard,
    private _appUpdate: DashboardUpdateService,
    public network: Network,
    // private tealium: Tealium,
    private tealium: TealiumUtagService,
    private companyDetails: GlobalService,
    //private localNotifications: LocalNotifications,

    // private ga : GoogleAnalytics

  ) {
    this.tealium.setConfig({
      "account": TealiumConfig.account,
      "profile": TealiumConfig.profile,
      "environment": TealiumConfig.environment
    });
    this.OnedayPassShow = this.companyDetails.OnedayPass;
    this.GiftamembershipShow = this.companyDetails.Giftamembership;;
    this.Reedeemamembership = this.companyDetails.Reedeemamembership;

    if (this.companyDetails.Giftamembership) {
      let gvMObj = {
        title: 'GIFTAMEMBERSHIP',
        url: './gift-voucher/type',
        icon: 'gift_a_membership.svg',
        activeUrl: '/gift-voucher/type',
        web: true,
        newkey: "Gift A Membership"
      };
      this.pages.splice(5, 0, gvMObj);

    }

    if (this.companyDetails.Reedeemamembership) {
      let reedeem = {
        title: 'REDEEMAMEMBERSHIP',
        url: './redeem-membership',
        icon: 'noun_Coupon_123779.svg',
        activeUrl: 'redeem-membership',
        web: true,
        newkey: "Redeem A Membership"
      }
      this.pages.splice(5, 0, reedeem);
    }

    if (this.companyDetails.OnedayPass) {
      let onedaypass =
      {
        title: 'ONEDAYPASS',
        url: './one-day-pass-info',
        icon: 'onedaypass.svg',
        activeUrl: 'one-day-pass-info',
        web: true,
        newkey: "One day pass"
      }
      this.pages.splice(this.pages[5].title == "REDEEMAMEMBERSHIP" ? 6 : 5, 0, onedaypass);

    }

    if (this.companyDetails.Gift_Voucher_Redemption_And_Purchase) {
      let onedaypass =
      {
        title: 'Gift Voucher',
        url: './gift-voucher/1',
        activeUrl: '/gift-voucher/1',
        icon: 'noun_no money_1056231.svg',
        web: true,
        newkey: 'Gift Voucher Redemption And Purchase'
      }
      this.pages.splice(this.pages[5].title == "REDEEMAMEMBERSHIP" ? 6 : 5, 0, onedaypass);
    }

    if (this.platform.is('android') || this.platform.is('ios') || this.platform.is('mobile')) {
      let language = {
        title: 'TAB3_TITLE',
        url: './change-language',
        activeUrl: "change-language",
        icon: 'setting.svg',
        web: true
      }
      this.pages.splice(this.pages.length - 2, 0, language);

    }

    this.initializeApp();
    this.InitializeWebApp();

    this.pages = Array.from(new Set(this.pages));
    events.subscribe('gcmLogout', (type) => {
      type == 'userexpired' ? this.logOutviaEvent('userexpired') : this.logOutviaEvent()
    });

    events.subscribe('OTPloggedin', () => {
     // this.InitializeWebApp();
      this.checkEnabledFeatures();
    });




    this.currentUrl = this.router.url;
    this.navigateUrl = this.currentUrl;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
    //geturlClass();

    this.preLoginPagesArray = ['select-language', 'login', 'center-selection/:id', 'otp/:id', 'dashboard']

    //  this.setPortrait();


    this.profileURL = './assets/img/ClientDefaultImage.svg';


    this.storage.get('LNG_KEY').then(lngkey => {
      if (lngkey) {
        let dir;
        if (lngkey == 'en-US') {
          dir = 'ltr'
        } else {
          dir = 'rtl'
        }
      } else {
      }
    });

    this.picUrl = ''
    this.firstName = ''
    this.LastName = ''
    this.memberStatus = ''
    this.center = ''
  }

  ngOnInit() {
    console.log('page reload');
    //this.InitializeWebApp();
  }

  geturlClass(url: string) {
    if (this.previousUrl == url) return true;
    else return false;
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  setPortrait() {
    this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
  }


  public isDesktop() {
    if (this.platform.is('android')) {
      window.localStorage.setItem('platform', 'android');
    } else if (this.platform.is('ios')) {
      window.localStorage.setItem('platform', 'ios');
    } else {
      this.web = true;
      window.localStorage.setItem('platform', 'web');
    }
  }

  navto(pagename, title) {
    //this.zone.run()p
    if (pagename == './change-language') {
      this.GoToSettings();
    }
    else if (pagename == './one-day-pass-info') {

      this.router.navigate(['/one-day-pass-info'], { skipLocationChange: false, replaceUrl: true });
    }
    else if (pagename == './logoutconfirm') {
      this.openLogoutAlert();
    }
    else if (pagename == './buy-membership/type') {
      const navigateData = {
        'cart': false,
        type: "buy"
      }
      this.dataService.setData('memberDetails', navigateData);
      this.router.navigate(['/buy-membership/memberDetails'], { skipLocationChange: false, replaceUrl: true });

    }
    else if (pagename == './gift-voucher/type') {
      const navigateData = {
        type: 'giftamembership'
      }
      this.dataService.setData('type', navigateData);
      this.router.navigate(['/gift-voucher/type'], { skipLocationChange: false, replaceUrl: true });

    } else if (pagename == './gift-voucher/1') {
      this.storage.remove('giftamemtype');
      this.router.navigate(['/gift-voucher/1'], { skipLocationChange: false, replaceUrl: true });

    }
    else if (pagename == './cart') {
      const navigateData = {
        'cart': true,
      }
      this.dataService.setData('type', navigateData);
      this.router.navigate(['/buy-membership/type'], { skipLocationChange: false, replaceUrl: true });
    }
    else {
      this.router.navigate([pagename])
    }

    this.selectedPage = pagename;

  }

  navtoProfile() {
    this.router.navigate(['./myprofile'], { skipLocationChange: false, replaceUrl: true });
    this.menu.close();
  }

  InitializeWebApp() {
    if (!this.platform.is('cordova')) {
      let hours = 24; // Reset when storage is more than 24hours
      let now = new Date().getTime();
      this.storage.get("SubDomainCacheTime").then((setupTime: any) => {
        this.storage.get("SubDomainCacheUrl").then((url: string) => {
          this.storage.get("companyDetails").then((_companyDetails) => {
            if (setupTime) {
              if (now - setupTime > hours * 60 * 60 * 1000) {
                this.GetCompanyDetails(now);
              }
              else {
                // set company details from local storage to service.ts
                if (!(url.toLocaleLowerCase() == window.location.hostname.toString().toLocaleLowerCase()))
                  this.GetCompanyDetails(now);
                else {
                  if (_companyDetails) {
                    this.companyDetails.CompanyId = Number(_companyDetails["CompanyId"]);
                    this.companyDetails.clientLogo = _companyDetails["clientLogo"];
                    this.companyDetails.identityURL = _companyDetails["identityURL"];
                    this.companyDetails.IsMemberPortalEnabled = _companyDetails["IsMemberPortalEnabled"];
                    this.companyDetails.MemberPortalClientId = _companyDetails["MemberPortalClientId"];
                    this.companyDetails.MobileAppClientId = _companyDetails["MobileAppClientId"];
                    this.companyDetails.Apptype = _companyDetails["Apptype"];
                  }
                }
              }

            }
            else {
              this.GetCompanyDetails(now);
            }
          });
        });
      });

    }
  }

  GetCompanyDetails(now) {
    let hostUrl = window.location.hostname.toString();
    // hostUrl = "fftest12.fitnessforce.com";
    let request = {
      "subdomain": hostUrl,
    }
    this.HTPS.GetCompanyDetails(request).subscribe(response => {
      if (response && response.status == 200 && response['data']) {
        if (response["data"]["error"]) {
          this.router.navigate(['/company-not-found']);
        }
        else {
          let _companyDetails = response['data'];
          _companyDetails["isMemberPortalEnabled"] = true;
          // companyDetails["companyID"]=0;
          if (Number(_companyDetails['companyID']) == 0) {
            this.router.navigate(['/company-not-found']);
          }
          else if (!_companyDetails["isMemberPortalEnabled"]) {
            this.router.navigate(['/company-not-enabled']);
          }
          else {
            this.companyDetails.CompanyId = Number(_companyDetails["CompanyID"]);
            this.companyDetails.clientLogo = _companyDetails["CompanyLogoUrl"];
            this.companyDetails.identityURL = _companyDetails["identityUrl"];
            this.companyDetails.IsMemberPortalEnabled = _companyDetails["isMemberPortalEnabled"];
            this.companyDetails.MemberPortalClientId = _companyDetails["clientCP"];
            this.companyDetails.MobileAppClientId = _companyDetails["clientMobileApp"];
            this.companyDetails.Apptype= _companyDetails["appType"];
            this.storage.set("companyDetails", this.companyDetails);
            this.storage.set("SubDomainCacheTime", now);
            this.storage.set("SubDomainCacheUrl", hostUrl);
          }
        }
      }
      else {
        this.router.navigate(['/company-not-found']);
      }
    });
  }



  initializeApp() {
    let tenantID;
    this.storage.get('USER_STORE').then((val) => {
      if (val) {
        tenantID = val.tenantID;
        if (!tenantID) {
          if (this.platform.is('cordova')) {
            this.events.publish("gcmLogout");
          }
          else if (!this.platform.is('cordova')) {
            this.logout.clearData()
          }
        }


        let enableBody = {
          "centerid": tenantID,
          "companyid": this.companyDetails.CompanyId,
        }
        this.HTPS.CheckEnable(enableBody).subscribe(responseEnable => {

          if (responseEnable && responseEnable['data']) {
            responseEnable.data.map(data => {

              if (data['featureName'].includes('Mobileapp') || data['featureName'].includes('ClientPortal')) {
                if (data['isEnabled']) {

                } else {
                  if (this.platform.is('cordova')) {
                    this.events.publish("gcmLogout", 'userexpired');
                  }

                  else if (!this.platform.is('cordova')) {
                    this.logout.clearData('userexpired')
                  }
                }
              }
            })
          }

        })
      }
    });

    this.storage.set('isManualUpAlertClosed', true).then(val => console.log(val));
    this.Apptype = this.companyDetails.Apptype
    //this.translate.use("en-US");

    this.storage.get('USER_STORE').then((user1) => {
      let tenantId = user1.tenantID;


      this.storage.get('tocken').then(toc => {
        if (toc) {
          if (!tenantId) {
            if (this.platform.is('cordova')) {
              this.events.publish("gcmLogout");
            }

            else if (!this.platform.is('cordova')) {
              this.logout.clearData()
            }
          }

        }
      })
    });

    let Val = false;
    this.storage.get('tocken').then(value => {
      if (value) {
        Val = true;
      }
    });



    this.storage.get('login').then(value => {
      if (value) {
        if (!Val) {
          //  this.logout.clearData();
        }
      }

    });



    this.platform.ready().then(() => {
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {
        if (this.companyDetails.Apptype == 'leejam') {
          this.presentSplashModal();
        }
        this.platform.resume.subscribe(() => {
          this.storage.get('isManualUpAlertClosed').then(res => {
            if (!res) {
              this.updateApp.isAlertOpen.next(true);
            }
          })
        })
        setTimeout(() => {
          this.updateAlertSubscription = this.updateApp.isAlertOpen.subscribe(isAlertOpen => {
            if (isAlertOpen) {
              this.updateApp.updateApp();
            }
          })
        }, 4000);
      }
      this.backButtonEvent();



      this.languageService.setInitialAppLanguage();

      if (this.platform.is('cordova')) {
        this.setPortrait();
      }

      if (this.platform.is('android') || this.platform.is('ios')) {
        let
          appEl = <HTMLElement>(document.getElementsByTagName('ION-APP')[0]),
          appElHeight = appEl.clientHeight;

        this.keyboard.disableScroll(true);

        window.addEventListener('native.keyboardshow', (e) => {
          appEl.style.height = (appElHeight - (<any>e).keyboardHeight) + 'px';
        });

        window.addEventListener('native.keyboardhide', () => {
          appEl.style.height = '100%';
        });
      }
      this.splashScreen.hide();

      setTimeout(() => {
        if (this.network.type == 'none') {
          if (this.platform.is('cordova')) {
            this.presentNWModal()
          }

        }
      }, 2000);


      this.networkSubscriber();
      this.theme.storedThemetype.then(theme => {
        this.themeName = theme;
        this.theme.setTheme(theme);
      });


      this.isDesktop();

      //this.backButtonEvent();

      this.router.events.subscribe((val) => {
        if (this.location.path() != '') {
          this.route = this.location.path();
          this.route = this.route.replace('/', '');

        } else {
        }
      });

      if (this.platform.is('ios')) {
        this.fcm2.grantPermission()
          .then((success) => {
            this.fcm2.getToken().then(token => {
              this.storage.get('tocken').then(v => {
                let bo = {
                  "deviceId": this.devicee.uuid,
                  "gcmToken": token
                }
                if (v) {
                  this.HTPS.Fcmtokenget(bo, v).then(fcmres => {
                  }, error => {
                  })
                }
              })
            });
          });

        this.fcm2.getToken().then(token => {
          this.storage.get('tocken').then(v => {
            let bo = {
              "deviceId": this.devicee.uuid,
              "gcmToken": token
            }
            if (v) {
              this.HTPS.Fcmtokenget(bo, v).then(fcmres => {
              }, error => {
              })
            }
          })
        });


      } 


      // if (this.platform.is('ios')) {
      //   this.fcm2.onTokenRefresh().subscribe(token => {
      //     let postdata = {
      //       "client_id": "007",
      //       "FcmKey": "" + token
      //     };
      //   });

      // } else {
      //   this.fcm.onTokenRefresh().subscribe(token => {
      //     let postdata = {
      //       "client_id": "007",
      //       "FcmKey": "" + token
      //     };
      //   });
      // }


     // if (this.platform.is('ios')) {
        this.fcm2.onMessageReceived().subscribe(data => {
          if (data.wasTapped) {
            if (data.payload == "message") {
              this.zone.run(() => {
                this.ModalForNotification(data)
              })
            }
            let check = data['google.sent_time'];
            let checkValid = moment(check).add(15, "minute").isAfter(moment());
            if (checkValid) {
              if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
                //this.popflag = true;

                setTimeout(() => {
                  this.router.navigateByUrl('class-attendance-popup');
                  this.storage.set('attendance', data)
                }, 1500);
              }

              else if (data.reminder == "true") {
                //this.popflag = true;
                setTimeout(() => {
                  window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");

                }, 1500);
              }

              if (data.payload == "keyfromapi") {
                setTimeout(() => {
                  this.router.navigate(['/my-schedule'], { replaceUrl: true });
                }, 1500);
              }

              if (data.payload == "appointmentattendance" && data.reminder == "false") {
                //this.popflag = true;
                setTimeout(() => {
                  this.storage.set('rating-data', data)
                  this.router.navigateByUrl('class-rating')
                }, 1500);
              } else {
                this.router.navigate(['/dashboard']);
              }



            }

            else {
              this.noOTPAlert('Notification Expired');
            }

          }

          else                       //FOREGROUND CODE
          {
            if (data.payload == "message") {
              this.zone.run(() => {
                this.ModalForNotification(data)
              })
            }

            if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
              //this.popflag = true;
              this.storage.set('attendance', data);
              setTimeout(() => {
                this.router.navigateByUrl('class-attendance-popup');
              }, 1500);
            }

            else if (data.reminder == "true") {
              if (this.platform.is('ios')) {
                window.open("http://maps.apple.com/?q=" + data.lat + "," + data.long, "_system");
              } else if (this.platform.is('android')) {
                window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");
              }
            }

            if (data.payload == "appointmentattendance" && data.reminder == "false") {
              setTimeout(() => {
                this.storage.set('rating-data', data)
                this.router.navigateByUrl('class-rating')

              }, 1500);
            }
            if (data.payload == "onedaypass") {
              if (data.deviceId == String(this.devicee.uuid)) {
                if (data.entryaccess == "true") {
                  setTimeout(() => {
                    const entry = data.entryaccess;
                    this.timestamp = moment().format("YYYY-MM-DD HH:mm ss SSS");
                    let dataTimestamp = {
                      timestamp: this.timestamp,
                      notificationsdata: data
                    }

                    this.storage.set('notitimestamp', dataTimestamp).then(() => {
                    });

                    let getnotitimestamp;
                    this.storage.get('notitimestamp').then(() => {
                    });
                    this.storage.remove('tenantNameforOnedayPass');
                    this.storage.set('tenantNameforOnedayPass', data.tenantName);
                    this.events.publish('tenantAccess');
                    this.presentGateModal(entry, this.firstName, this.LastName, data.tenantName);
                  }, 300)
                } else {
                  setTimeout(() => {
                    // const entry = data.entryaccess;
                    this.presentAlertUnsuccessful(data.accessStatusReason);
                  }, 300)
                }
              }
            }
          }
        })


     // } 
      // else 
      // {

      //   this.fcm.onNotification().subscribe(data => {
      //     if (data.wasTapped) {
      //       if (data.payload == "message") {
      //         //this.events.publish('messegenotification',data);
      //         this.zone.run(() => {
      //           this.ModalForNotification(data)
      //         })
      //       }

      //       let check = data['google.sent_time'];
      //       let checkValid = moment(check).add(15, "minute").isAfter(moment());
      //       if (checkValid) {
      //         if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
      //           setTimeout(() => {
      //             this.router.navigateByUrl('class-attendance-popup');
      //             this.storage.set('attendance', data)
      //           }, 1500);
      //         }

      //         else if (data.reminder == "true") {
      //           setTimeout(() => {
      //             window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");
      //           }, 1500);
      //         }

      //         if (data.payload == "keyfromapi") {
      //           setTimeout(() => {
      //             this.router.navigate(['/my-schedule'], { replaceUrl: true });
      //           }, 1500);
      //         }

      //         if (data.payload == "appointmentattendance" && data.reminder == "false") {
      //           setTimeout(() => {
      //             this.storage.set('rating-data', data)
      //             this.router.navigateByUrl('class-rating')
      //           }, 1500);
      //         } else {
      //           this.router.navigate(['/dashboard']);
      //         }
      //       }
      //       else {
      //         this.noOTPAlert('Notification Expired');
      //       }
      //     }
      //     else                       //FOREGROUND CODE
      //     {
      //       if (data.payload == "message") {
      //         this.zone.run(() => {
      //           this.ModalForNotification(data)
      //         })
      //       }
      //       if (data.payload == "AttendancePushNotification" && data.reminder == "false") {
      //         this.storage.set('attendance', data);
      //         setTimeout(() => {
      //           this.router.navigateByUrl('class-attendance-popup');
      //         }, 1500);
      //       }

      //       else if (data.reminder == "true") {
      //         if (this.platform.is('ios')) {
      //           window.open("http://maps.apple.com/?q=" + data.lat + "," + data.long, "_system");
      //         } else if (this.platform.is('android')) {
      //           window.open("http://maps.google.com/?q=" + data.lat + "," + data.long, "_system");
      //         }
      //       }

      //       if (data.payload == "appointmentattendance" && data.reminder == "false") {
      //         setTimeout(() => {
      //           this.storage.set('rating-data', data)
      //           this.router.navigateByUrl('class-rating')

      //         }, 1500);
      //       }
      //       if (data.payload == "onedaypass") {
      //         if (data.deviceId == String(this.devicee.uuid)) {
      //           if (data.entryaccess == "true") {
      //             setTimeout(() => {
      //               const entry = data.entryaccess;
      //               this.timestamp = moment().format("YYYY-MM-DD HH:mm ss SSS");
      //               let dataTimestamp = {
      //                 timestamp: this.timestamp,
      //                 notificationsdata: data
      //               }
      //               this.storage.set('notitimestamp', dataTimestamp).then(() => {
      //               });

      //               let getnotitimestamp;
      //               this.storage.get('notitimestamp').then(() => {
      //               });
      //               this.storage.remove('tenantNameforOnedayPass');
      //               this.storage.set('tenantNameforOnedayPass', data.tenantName);
      //               this.events.publish('tenantAccess');

      //               this.presentGateModal(entry, this.firstName, this.LastName, data.tenantName);
      //             }, 300)
      //           } else {
      //             setTimeout(() => {
      //               this.presentAlertUnsuccessful(data.accessStatusReason);
      //             }, 300)
      //           }
      //         }

      //       }
      //     }
      //   })

      // }

      this.checkEnabledFeatures();
    });



    //  this.initTranslate();


    this.storage.get('profileData').then(data => {

      if (data) {
        this.zone.run(() => {
          this.picUrl = data['photoUrl']
          this.firstName = data['firstname']
          this.LastName = data['lastname']
          this.memberStatus = data['MemberStatus']
          this.center = data['center']
          this.centerStatus = data['centerStatus']
          this.logo = data['brandLogo']
        })
      }

      this.events.unsubscribe("SidemenuProfile");
      this.events.subscribe('SidemenuProfile', (s) => {
        this.storage.get('profileData').then(data => {
          if (data) {
            this.zone.run(() => {
              this.picUrl = data['photoUrl']
              this.firstName = data['firstname']
              this.LastName = data['lastname']
              this.memberStatus = data['MemberStatus']
              this.center = data['center']
              this.logo = data['brandLogo']
            })
          }

        })
      })

    });
  }




  initTranslate() {
    this.translate.setDefaultLang('en-US');
    if (this.translate.getBrowserLang() !== undefined) {
      let lang = 'en-US'
      if (this.translate.getBrowserLang() == 'ar') {
        lang = 'ar-SA'
      }
      this.translate.use(lang);
    } else {
      this.translate.use('en-US');
    }
  }






  //Back Button
  backButtonEvent() {

    this.platform.backButton.subscribe(async () => {
      try {
        let element = await this.modalCtrl.getTop();
        if (element) {
          if (this.router.url.includes("club-locator")) {
            this.router.navigate(['/club-locator/69']);
          }
          else
            if ((element.textContent).includes("No Internet")) {
              return;
            }
            else {
              element.dismiss();
              return;
            }
        }
      }
      catch (error) {
      }

      if ((this.router.url.includes('buy-membership') || this.router.url.includes('club-details')) || this.router.url.includes('image-full-view') || this.router.url.includes('photo-viewer') || this.router.url.includes('club-locator') || this.router.url.includes('otp') || this.router.url.includes('specific-trainer') || this.router.url.includes('video-list') || this.router.url.includes('video-play')) {
        this.events.publish("cartback");
      }
      else
        if (this.router.url == '/login') {
        }
        else {
          if (!this.preLoginPagesArray.includes(this.getPreviousUrl())) {

            if (this.router.url == '/one-day-pass-info') {
              this.router.navigate(['/dashboard']);
              return
            }

            if (this.router.url == '/redeem-membership') {
              this.router.navigate(['/dashboard']);
              return
            }


            if (this.router.url == "/dashboard" || this.router.url == "/select-language") {
              this.presentAlertConfirm();
            }
            else {
              if (this.router.url == ('/gift-voucher/type')) {
                this.events.subscribe('popupback', val => {
                  if (val == false) {
                    this.router.navigate(['/dashboard'])
                  }
                });
              } else
                if (this.getPreviousUrl() == '/classes') {
                  this.router.navigate(['/classes']);
                } else
                  if ((this.getPreviousUrl() == '/dashboard')) {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/my-schedule') {
                    this.router.navigate(['/my-schedule']);
                  } else if (this.getPreviousUrl() == '/class-details/classDetails') {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/class-details/memberDetails') {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/trainer-list') {
                    this.router.navigate(['/trainer-list']);
                  } else if (this.getPreviousUrl() == '/trainer-details/1') {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/feedback-home') {
                    this.router.navigate(['/feedback-home']);
                  } else if (this.getPreviousUrl() == '/feedback-email/memberDetails') {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/purchase-list') {
                    this.router.navigate(['/purchase-list']);
                  } else if (this.getPreviousUrl() == '/purchase-details/1') {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/date-time/memberDetails' || this.getPreviousUrl() == 'date-time/edit') {
                    this.router.navigate(['/dashboard']);
                  } else if (this.getPreviousUrl() == '/specific-trainer/memberDetails') {
                    this.router.navigate(['/specific-trainer/memberDetails']);
                  }
                  else {

                  }
            }
          }
        }

    });
  }

  async presentAlertConfirm() {
    let closeAppText
    this.translate.get('DOYOUWANTOCLOSETHEAPP').subscribe(value => {
      closeAppText = value;
    })

    let cancelText
    this.translate.get('CANCEL_BUTTON').subscribe(value => {
      cancelText = value;
    })

    let closeText
    this.translate.get('CLOSEAPP').subscribe(value => {
      closeText = value;
    })
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: closeAppText,
      buttons: [{
        text: cancelText,
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => { }
      }, {
        text: closeText,
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
    await alert.present();
  }


  toggleMenuState() {
    this.storage.get('profileData').then(data => {
      if (data) {
        this.zone.run(() => {
          this.picUrl = data['photoUrl']
          this.firstName = data['firstname']
          this.LastName = data['lastname']
          this.memberStatus = data['MemberStatus']
          this.center = data['center']
        })
      }
    });
  }

  async GoToSettings() {
    this.router.navigateByUrl('/settings')
  }
  //Direct logout Open

  async openLogoutAlert() {
    let logoutConfirm
    this.translate.get('DOYOUREALLYWANTTOLOGOUTFROMTHEAPPLICATION').subscribe(value => {
      logoutConfirm = value;
    })
    let yes
    this.translate.get('YES').subscribe(value => {
      yes = value;
    })

    let no
    this.translate.get('NO').subscribe(value => {
      no = value;
    })
    const alert = await this.alertController.create({
      message: logoutConfirm,
      buttons: [
        {
          text: no,
          handler: () => {
          }
        }, {
          text: yes,
          handler: () => {
            this.storage.get('tocken').then(toc => {
              this.HTPS.GcmTokenDelete(toc, this.devicee.uuid).subscribe((data) => {
                if (data.status == 200) {
                  this.logout.clearData()
                }
                else {
                  this.logOUTFailToast()
                }
              }, error => {
                this.logOUTFailToast()
              })

            })

          }
        }
      ]
    });

    await alert.present();
  }

  async noOTPAlert(m) {
    let NotifExpiredText
    this.translate.get('NOTIFICATIONEXPIRED').subscribe(value => {
      NotifExpiredText = value;
    })

    let expiredText
    this.translate.get('EXPIRED').subscribe(value => {
      expiredText = value;
    })
    let alert = await this.alertController.create({
      message: expiredText,
      subHeader: NotifExpiredText,
      buttons: [
        {
          text: expiredText,
          handler: () => {
            this.router.navigate(['./dashboard']);
          }
        }]
    });
    await alert.present();
  }


  //Through  BAck Button Click
  async CloseButton() {
    let closeAppText
    this.translate.get('DOYOUWANTOCLOSETHEAPP').subscribe(value => {
      closeAppText = value;
    })

    let cancelText
    this.translate.get('CANCEL_BUTTON').subscribe(value => {
      cancelText = value;
    })

    let closeAppButtonText
    this.translate.get('CLOSEAPP').subscribe(value => {
      closeAppButtonText = value;
    })
    const alert = await this.alertController.create({
      message: closeAppText,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          handler: (blah) => {
          }
        }, {
          text: closeAppButtonText,
          handler: () => {
            navigator['app'].exitApp();
          }
        }
      ]
    });

    await alert.present();
  }




  async presentToast() {
    this.translate.get('PRESSAGAINTOEXIT').subscribe(
      value => {
        this.ToastMessage = value;
      }
    )

    let toast = await this.toastCtrl.create({
      message: this.ToastMessage,
      duration: 6000,
      position: 'bottom'
    });
    return toast.present();

  }


  async logOUTFailToast() {
    this.translate.get('LOGOUTNONET').subscribe(
      value => {
        this.ToastMessage = value;
      }
    )

    let toast = await this.toastCtrl.create({
      message: this.ToastMessage,
      duration: 3000,
      position: 'bottom'
    });
    return toast.present();

  }

  networkSubscriber() {
    this.networkService
      .getNetworkStatus()
      .pipe(debounceTime(300))
      .subscribe((connected: boolean) => {
        let isConnected = connected;

        if (!isConnected) {
          if (this.platform.is('cordova')) {
            this.presentNWModal()
          }
        }
      });
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: ChangeLanguagePage,
      cssClass: 'my-custom-modal-css'
    });
  }

  async presentNWModal() {
    const modal = await this.modalCtrl.create({
      component: NetworkErrorPage
    });

    return await modal.present();
  }

  async presentGateModal(access, firstName, LastName, tenantName) {
    const modal = await this.modalCtrl.create({
      component: GateOpenClosePage,
      componentProps: {
        'access': access,
        'firstName': firstName,
        'LastName': LastName,
        'tenantName': tenantName
      }
    });
    return await modal.present();
  }

  async presentSplashModal() {
    const modal = await this.modalCtrl.create({
      component: GifSplashPage
    });
    return await modal.present();
  }

  ngOnDestroy() {
    this.platform.backButton.unsubscribe();
    this.updateAlertSubscription.unsubscribe();
  }

  getSide() {
    return (this.languageService.getSelectedLang() == 'rtl') ? 'end' : 'start';
  }

  isWeb(showOnWeb) {

    if (this.platform.is('mobileweb') || this.platform.is('desktop')) {
      return showOnWeb
    }
    else {
      return true
    }

  }


  logOutviaEvent(type?: any) {
    if (this.platform.is('cordova')) {
      this.storage.get('tocken').then(toc => {
        this.HTPS.GcmTokenDelete(toc, this.devicee.uuid).subscribe((data) => {
          if (data.status == 200) {
            if (type == 'userexpired') {
              this.logout.clearData('userexpired')
            } else {
              this.logout.clearData()
            }
          }
          else {
            this.logOUTFailToast()
          }
        }, error => {
          this.logOUTFailToast()
        })
      })
    }

  }

  async ModalForNotification(data) {
    const modal = await this.modalCtrl.create({
      component: NotificationViewModalPage,
      componentProps: {
        'notificationData': data,
      }
    });
    modal.onDidDismiss().then(val => {
    })
    return await modal.present();
  }


  //maintanainceamodeApi

  async checkMaintaninacemode() {

    let tenantID;
    let MemberID
    this.storage.get('USER_STORE').then((user1) => {
      tenantID = user1.tenantID;
      MemberID = user1.MemberID;
    });
    this.storage.get('tocken').then(toc => {

      if (toc) {
        let data;
        // this.HTPS.maintananceMode(toc, this.devicee.uuid).subscribe((data) => {
        if (data == true) {
          this.logOutviaEvent();
        }
        //  });
      }
    })



  }

  async presentAlertUnsuccessful(message) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: message,
      buttons: [
        {
          text: 'OK',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  checkEnabledFeatures() {
    this.storage.get('tocken').then((tocken) => {
      if (tocken) {
        this.storage.get('USER_STORE').then((US) => {
          this.storage.get('langSelect').then(lang => {

            if (US && US.tenantID && lang) {
              this.HTPS.v2BillingEnabledFeatures(US.tenantID, tocken, lang).subscribe((response) => {
                if (response) {
                  this.pagestoggel(response.data);
                  this.pages = Array.from(new Set(this.pages));
                  let enabled = response.data;
                  this.companyDetails.Giftamembership = enabled.includes("Gift membership") ? true : false;
                  this.companyDetails.Onboarding = enabled.includes("onboarding") ? true : false;
                  this.companyDetails.workoutvideospecial = enabled.includes("Workout Videos") ? true : false;
                  this.companyDetails.AppointmentFlag = enabled.includes("Appointment") ? true : false;
                  this.companyDetails.Reedeemamembership = enabled.includes("Redeem A Membership") ? true : false;
                  this.companyDetails.OnedayPass = enabled.includes("One day pass") ? true : false;
                  this.companyDetails.Gift_Voucher_Redemption_And_Purchase = enabled.includes("Gift Voucher Redemption And Purchase") ? true : false;
                  this.storage.set('enabledFeatures', enabled);
                  this.events.publish('checkEnabledFeatures', enabled);
                }
              });
            }
          })
        })
      }

    })
  }

  pagestoggel(response) {
    if (response) {
      var result = this.pages.filter((n) => {
        return response.indexOf(n['newkey']) > -1 || !n['newkey'];
      });

      this.zone.run(() => {
        this.pages = result    //change pages
      });
    }

  }

}

